import BaseModel from 'js/model/base';

export default class User extends BaseModel {
  get(uid, d = {withItemCounts: false}) {
    let path = `/user/${uid}`;
    if (d.withItemCounts) path = `${path}?count=1`;
    return this._runCallable(path);
  }

  me() {
    return this._runCallable('/me');
  }

  sync() {
    return this._runCallable('/user/sync');
  }
}
