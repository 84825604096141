import logoImg from 'assets/imgs/common/logo.svg';
import BasePartialView from 'js/view-partial/base';
import StatusPartialView from 'js/view-partial/status';
import UserSegment from 'js/common/user-segment';

export default class HeaderAndFooterPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.$hmb = undefined; // hamburger menu
    this.$ul = undefined; // menu list
  }

  run() {
    const path = window.location.pathname;
    if (path === '/') this._renderHeaderForTop();
    else this._renderHeader();

    const $m = this._renderBlankMain();
    this._renderFooter();

    return $m;
  }

  // private methods
  _renderHeaderForTop() {
    // 右メニューのみ
    this._renderHeaderNav($('body'), 'top');
  }

  _renderHeader() {
    const $h = $(`
      <header><div></div></header>
    `).appendTo('body');
    const $hdv = $h.find('div:first');

    // logo image
    $(`
      <h1>
      <a href="/t"><img src="${logoImg}" alt="ハック思考 HACK THINKING"></a>
      </h1>
    `).appendTo($hdv);

    // ハンバーガーメニュー
    this._renderHeaderNav($hdv);
  }

  _renderHeaderNav($hdv, path = '') {
    const $nav = $(`
      <nav>
      <p class="_hmb"><span></span></p>
      <ul class="_popup"></ul>
      </nav>
    `).appendTo($hdv);

    const $ul = $nav.find('ul._popup');
    const $hmb = $nav.find('p._hmb');
    this.$ul = $ul;
    this.$hmb = $hmb;

    // ログイン有無で中身変える
    if (this.c.current_user) {
      $nav.addClass('loggedin');
      const $li = $(`
        <li><a href="/a/activities">自分の投稿</a></li>
        <li><a href="/a/settings">設定</a></li>
        <li><a href="https://twitter.com/hack_thinking" target="_blank">お知らせ</a></li>
        <li><a href="/logout">ログアウト</a></li>
      `).appendTo($ul);

      // logout ボタンの処理予約
      const $lbtn = $li.find('a[href="/logout"]');
      this._setLogoutHandler($lbtn);

      // TOP ページのみ ネタ一覧リンク
      if (path === 'top')
        $(`<a href="/t" class="link">ネタ一覧へ</a>`).prependTo($nav);
    } else {
      // TOP ページは hamburger ナシ
      if (path === 'top') $hmb.remove();

      $(`
        <li><a href="/login">ログイン</a></li>
        <li><a href="/a/plan">新規登録</a></li>
      `).appendTo($ul);
    }

    // 開閉
    $hmb.on('click', e => {
      if ($ul.css('display') === 'none') {
        $(e.target).addClass('selected');
        $ul.fadeIn('fast');
      } else {
        this._closeNaviMenu();
      }
    });

    this._hideOnClickOutside($hmb, () => {
      this._closeNaviMenu();
    });
  }

  _renderBlankMain() {
    return $(`<main></main>`).appendTo('body');
  }

  _renderFooter() {
    const $b = $(`
      <footer>
      <ul>
      <li><a href="/privacy">プライバシー</a></li>
      <li><a href="/tos">ご利用規約</a></li>
      <li><a href="/law">特定商取引法</a></li>
      <li><a href="mailto:support@hack-thinking.zendesk.com">お問い合せ</a></li>
      <li><a href="https://twitter.com/hashtag/%E3%83%8F%E3%83%83%E3%82%AF%E6%80%9D%E8%80%83" target="_blank">#ハック思考 <i class="fab fa-twitter" style="font-size: 0.9em"></i></a></li>
      </ul>
      <h3>&copy; 2020 bashika LLC</h3>
      </footer>
    `).appendTo('body');
    if (window.location.pathname !== '/') {
      $b.find('ul').append('<li><a href="/">サイトトップ</a></li>');
    }
  }

  _setLogoutHandler($btn) {
    $btn.on('click', () => {
      const ok = window.confirm('ログアウトします');
      if (!ok) return false;
      console.log('#_setLogoutHandler confirmed');
      firebase
        .auth()
        .signOut()
        .then(() => {
          StatusPartialView.setFlash('success', 'ログアウトしました', true);

          // update user segment
          new UserSegment().updateForEvent('logout');

          console.log('#_setLogoutHandler signout done');
          window.location = '/login';
        });
      return false;
    });
  }

  _closeNaviMenu() {
    if (!this.$hmb || !this.$ul) return;
    this.$hmb.removeClass('selected');
    this.$ul.fadeOut('fast');
  }
}
