import BaseListWithPagerPartialView from 'js/view-partial/list-with-pager/base';
import InvoiceItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/invoice';

export default class InvoiceListWithPagerPartialView extends BaseListWithPagerPartialView {
  constructor(h = {}) {
    super(h);
    this.domId = 'invoice-list';
    this.responseNodeName = 'invoices';
    this.getList = param => {
      return this.c.subsc.invoiceList(param);
    };
  }

  _getListItemView(opt) {
    return new InvoiceItemForListWithPagerPartialView(opt);
  }

  _pagerOptions() {
    return {summaryType: 'no-total'};
  }
}
