import BasePartialView from 'js/view-partial/base';
import HeartActionItemPartialView from 'js/view-partial/action-item/heart';
import PlanUpgradeButtonPartialView from 'js/view-partial/plan-upgrade-button';
import UserPartialView from 'js/view-partial/user';
import TextViewHelper from 'js/view-helper/text';
import TinyLoaderViewHelper from 'js/view-helper/tiny-loader';

export default class BaseItemForListWithPagerPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.md = h.md || {}; // model data
    this.$b = undefined;
    this.name = '';
    this.itemLabel = 'アイテム';
    this.editMenuViewClass = undefined;
    this.showName = h.showName !== undefined ? h.showName : true;
    this.showItemTypeMark =
      h.showItemTypeMark !== undefined ? h.showItemTypeMark : false;
  }

  render() {
    const $b = $(`
      <li id="t-${this.md.id}">
      <b><a class="_${this.name}"><i class="fas fa-lock"></i><font class="_text"></font></a></b>
      <span><a class="_user"></a><font class="_dt"></font></span>
      <em><i class="far fa-comment-dots"></i><font></font></em>
      </li>
    `);
    this.$b = $b;

    // heart icon 挿入
    const ht = new HeartActionItemPartialView({
      c: this.c,
      parentView: this,
    });
    ht.render().appendTo($b);

    // 値を挿入
    this._setDataToDom(this.md);

    // text link click 時に tiny loader 出す
    this._setOnLinkClick();

    // topic の持ち主には Edit Menu 追加
    this._addEditMenu();

    console.log(`${this.name} item#render`, this.md);
    return $b;
  }

  // private methods
  _setDataToDom(md) {
    const {$b} = this;
    const ud = md.user || {};
    const dt = moment(md.createdAt).fromNow();

    if (!md.data.locked) $b.find('i.fa-lock').remove();
    const linkUrl = this._makeContentLink(md);
    const $txtLink = $b.find(`a._${this.name}`).attr('href', linkUrl);

    // 行動履歴のいいね List 等では先頭にラベル文言を付与
    if (this.showItemTypeMark) $txtLink.addClass(`mark-${this.name}`);

    const $txt = $txtLink.find('._text');
    if (md.state === 'active') {
      const opt = {mode: 'list'};
      const text = TextViewHelper.filterTextForDisp(md.data.text, opt);
      $txt.append(text);
    } else {
      $txt.append(
        `<font color="#AAA">この${this.itemLabel}は削除されました</font>`
      );
    }

    const $au = $b.find('a._user');
    if (this.showName) {
      const html = UserPartialView.nameLinkHtml(md.owner, ud.screenName);
      $au.replaceWith(html);
    } else {
      $au.remove();
    }

    $b.find('font._dt')
      .empty()
      .append(dt);

    // 返信の場合は吹き出しアイコンを撤去
    const $cd = $b.find('i.fa-comment-dots');
    if (!('commentCount' in md)) {
      $cd.parent('em').remove();
    } else {
      $cd.next('font').text(md.commentCount);
      //if (md.commentCount > 0) $cd.removeClass('far').addClass('fas');
      //else $cd.removeClass('fas').addClass('far');
    }

    // 登録プランによる差
    const dtp = md.dispType;
    if (dtp.match(/locked-(free|non-login)/) && this.name !== 'topic') {
      const $bt = $b.find('b:first').addClass('locked');
      const type = RegExp.$1;
      if ($b.find('a._register').length === 0) {
        const $up = new PlanUpgradeButtonPartialView({
          c: this.c,
          type: type,
          triggerUrl: linkUrl,
        });
        $up.render().insertAfter($bt);
      }
    }
  }

  _addEditMenu() {
    if (this.md.dispType !== 'owner') return;
    if (this.md.state !== 'active') return;
    const {$b} = this;

    let ec;
    if (this.name === 'topic') ec = 'question';
    else if (this.name.match(/^(child-)?comment/)) ec = 'comment';
    if (ec) $b.addClass(ec);

    const opt = {
      c: this.c,
      md: this.md,
      $target: $b,
      addToDom: ($i, $ul) => {
        $b.find('b:first').after($i, $ul);
      },
    };
    const em = new this.editMenuViewClass(opt);
    em.render();

    $('body').on(`${this.name}:update:success`, (e, md) => {
      if (this.md.id !== md.id) return;
      this.md = md;
      this._setDataToDom(md); // 画面に更新内容を反映
    });

    $('body').on(`${this.name}:delete:success`, (e, md) => {
      if (this.md.id !== md.id) return;
      $b.remove();
      console.log('削除しました');
    });
  }

  _setOnLinkClick() {
    let clicked = false;
    this.$b.find(`a._${this.name}`).on('click', e => {
      if (clicked) return false;
      clicked = true;
      const $ld = new TinyLoaderViewHelper();
      const $a = $(e.delegateTarget);
      $a.css('color', '#AAA').prepend($ld.render());

      // 数秒後に解除 (for 別タブでリンク開いた人用)
      setTimeout(() => {
        $ld.remove();
        $a.css('color', '');
        clicked = false;
      }, 3000);
    });
  }

  // define in sub classes
  _makeContentLink() {
    return 'https://...';
  }
}
