import BaseView from 'js/view/base';

export default class TopServiceUnavailableView extends BaseView {
  run() {
    // まだ共通 header/footer が無いなら表示
    if ($('body footer').length === 0) super.run();

    this._setTitle('システムエラー');
    this._render();
    console.log('#run done');
  }

  // private methods
  _render() {
    // 途中まで描画した UI をリセット
    let $m = $('body main');
    if ($m.length > 0) $m.empty();
    else $m = $('<main></main>').appendTo('body');

    $(`
      <section class="notes">
      <h1>ごめんなさい<br>システムエラーです</h1>
      </section>
    `).appendTo($m);
  }
}
