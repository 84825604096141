import HeaderAndFooterPartialView from 'js/view-partial/header-and-footer';
import StatusPartialView from 'js/view-partial/status';

export default class BaseView {
  constructor(h = {}) {
    this.siteName = 'ハック思考';
    this.c = h.c; // controller instance
  }

  run() {
    const $m = new HeaderAndFooterPartialView({c: this.c}).run();
    StatusPartialView.showFlash();
    return $m;
  }

  // private methods
  _setTitle(label) {
    $('title').text(`${label} | ${this.siteName}`);
    $('meta[property="og:title"]').attr(
      'content',
      `${label} | ${this.siteName}`
    );
    $('meta[property="og:url"]').attr('content', window.location.href);
  }
}
