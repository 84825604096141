import EditMenuBasePartialView from 'js/view-partial/edit-menu/base';
import SlideFormChildCommentPartialView from 'js/view-partial/slide-form/child-comment';

export default class EditMenuChildCommentPartialView extends EditMenuBasePartialView {
  constructor(h = {}) {
    super(h);
    this.modelLabel = '返信';
    this.model = this.c.childComment;
    this.name = 'child-comment';
  }

  _getSlideFormPartialView() {
    const opt = {
      c: this.c,
      md: this.md,
      parentCommentId: this.md.parentCommentId,
    };
    return new SlideFormChildCommentPartialView(opt);
  }
}
