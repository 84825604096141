import BaseViewHelper from 'js/view-helper/base';
import Autolinker from 'autolinker'; // https://www.npmjs.com/package/autolinker

const autolinker = new Autolinker({
  hashtag: 'twitter',
  truncate: {length: 20, location: 'smart'},
});

export default class TextViewHelper extends BaseViewHelper {
  static filterTextForDisp(text, d = {mode: 'desc'}) {
    if (text === undefined || text === '' || text === null) return;
    let t = text;

    // HTML escape
    if (/[<>&]/.test(t)) t = this.escapeHTMLStr(t);

    // "---" Lock 区切りマークを除去 (最初のマークのみ)
    const marks = /---|—-|———/;
    if (t.match(marks)) t = t.replace(marks, '');

    // preview text の場合、末尾に余計な "\n..." があるので除去
    const ltRgx = /(\r\n|\r|\n)\.\.\.$/;
    if (t.match(ltRgx)) t = t.replace(ltRgx, '');

    // 一覧モードの場合
    if (d.mode === 'list') {
      // 最初の double 改行で切る
      [t] = t.split(/(\r\n|\r|\n){2}/);
      // 改行を space に変換
      t = t.replace(/(\r\n|\r|\n)/g, ' ');
      // 指定 length で切る
      const length = 100;
      if (t.length > length) t = `${t.substr(0, length)}･･･`;
    }

    // 改行を br に変換
    t = t.replace(/(\r\n|\r|\n)/g, '<br>');

    // URL string を link tag に変換
    t = autolinker.link(t);

    console.log(t);

    return t;
  }

  static escapeHTMLStr(text) {
    const def = {'&': '&amp;', '<': '&lt;', '>': '&gt;'};
    return text.replace(/[&<>]/g, tag => {
      return def[tag] || tag;
    });
  }

  static copyToClipboard(str) {
    // via http://bit.ly/3cibRUm
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  static dispNameFor(name) {
    return !name ? '@退会' : `@${name}`;
  }
}
