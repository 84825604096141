import BasePartialView from 'js/view-partial/base';
import LoadingPartialView from 'js/view-partial/loading';
import PopUpMenuPartialView from 'js/view-partial/popup-menu';
import HilightFadeViewHelper from 'js/view-helper/hilight-fade';

export default class EditMenuBasePartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.md = h.md || {}; // model data
    this.$target = h.$target || undefined;
    this.addToDom = h.addToDom || (() => {});

    // override with sub class
    this.itemsDef = [
      {action: 'update', text: '編集する'},
      {action: 'delete', text: '削除する'},
    ];
    this.modelLabel = 'アイテム';
    this.model = undefined;
    this.name = '';
  }

  render() {
    const $i = $('<i class="fas fa-chevron-down"></i>');

    // popup menu 定義
    const $pm = this._initPopupMenu($i);

    // dom に追加
    this.addToDom($i, $pm);
  }

  _initPopupMenu($i) {
    const opt = {
      c: this.c,
      className: 'edit',
      $opener: $i,
      def: this.itemsDef,
    };
    const pop = new PopUpMenuPartialView(opt);
    pop.setActionFor('update', () => {
      // 編集フォーム表示
      this._showItemEditForm();
    });
    pop.setActionFor('delete', () => {
      // 削除確認
      const ok = confirm(`${this.modelLabel}を削除します`);
      if (!ok) return;
      this._deleteItem();
    });
    return pop.render();
  }

  _showItemEditForm() {
    const v = this._getSlideFormPartialView();
    v.render('update').appendTo('main');
    v.show();

    $('body').on(`${this.name}:update:success`, (e, md) => {
      if (this.md.id !== md.id) return;
      this.md = md;
      HilightFadeViewHelper.hilight(this.$target);
    });
  }

  _deleteItem() {
    // call 元 target element の中身をまるっと消して loading 表示
    this.$target.empty();
    new LoadingPartialView({c: this.c}).render().appendTo(this.$target);

    this.model
      .delete(this.md.id)
      .then(() => $('body').trigger(`${this.name}:delete:success`, [this.md]))
      .catch(e => console.error('#_deleteItem', e));
    console.log(`${this.name}:delete:success`);
  }

  // override with sub class
  _getSlideFormPartialView() {
    return true;
  }
}
