import BaseViewHelper from 'js/view-helper/base';

export default class TinyLoaderViewHelper extends BaseViewHelper {
  constructor() {
    super();
    this.$loader = undefined;
  }

  render() {
    const $ld = $(`<i class="loading _tiny-ldr"></i>`);
    this.$loader = $ld;
    return $ld;
  }

  remove() {
    this.$loader.remove();
  }

  existsInside($b) {
    return $b.find('i._tiny-ldr').length > 0 ? true : false;
  }
}
