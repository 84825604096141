import BaseListWithPagerPartialView from 'js/view-partial/list-with-pager/base';
import ChildCommentItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/child-comment';
import CommentItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/comment';
import TopicItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/topic';

export default class LikeListWithPagerPartialView extends BaseListWithPagerPartialView {
  constructor(h = {}) {
    super(h);
    this.domId = 'like-list';
    this.responseNodeName = 'likedItems';
    this.getList = param => {
      return this.c.like.list(param);
    };
  }

  _getListItemView(opt) {
    if (opt.md.class === 'Topic')
      return new TopicItemForListWithPagerPartialView(opt);
    else if (opt.md.parentCommentId)
      return new ChildCommentItemForListWithPagerPartialView(opt);
    else return new CommentItemForListWithPagerPartialView(opt);
  }
}
