import BaseViewHelper from 'js/view-helper/base';

export default class LabelViewHelper extends BaseViewHelper {
  static planById(id) {
    const def = {
      paid: '有料プラン',
      free: '無料プラン',
    };
    return def[id];
  }
}
