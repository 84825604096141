import BaseViewHelper from 'js/view-helper/base';
import HilightFadeViewHelper from 'js/view-helper/hilight-fade';

export default class WindowViewHelper extends BaseViewHelper {
  static scrollTo($itm, opt = {}) {
    opt = Object.assign({hilight: true, delay: 200, block: 'center'}, opt);
    if ($itm.length === 0) return;

    // doc https://mzl.la/32UHAGW
    $itm[0].scrollIntoView({behavior: 'smooth', block: opt.block});

    if (opt.hilight) HilightFadeViewHelper.hilight($itm, opt.delay);
  }
}
