import BasePartialView from 'js/view-partial/base';
import ButtonViewHelper from 'js/view-helper/button';

export default class SlideFormBasePartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.md = h.md || {}; // model data
    this.$b = undefined; // render した dom
    this.name = undefined;
    this.closeOnSubmitSuccess =
      h.closeOnSubmitSuccess === undefined ? true : h.closeOnSubmitSuccess;
  }

  render(type = 'create') {
    // 既に設置済みなら何もしない
    if ($(`section._form_${this.name}`).length > 0) return;

    const lbl = this._getLabels(type);
    const $b = $(`
      <section class="slide _form_${this.name}">
      <div>
      <form action="" method="post">
      <h3><a href="" class="close"><i class="fas fa-arrow-left"></i></a>${lbl.title}</h3>
      <textarea name="text"></textarea>
      <label class="checkbox _locked">
      <input type="checkbox" name="locked" value="1">
      <span></span>ロックする <i class="fas fa-lock"></i></label>
      <button type="button" class="submit">${lbl.btn}</button>
      </form>
      </div>
      </section>
    `);
    this.$b = $b;

    // 値を挿入
    this._setDataToDom(this.md, type);

    // form を閉じる処理予約
    const $closeBtn = $b.find('a.close');
    this._registerFormClose($closeBtn);

    // submit 処理予約
    this._registerSubmit($closeBtn, type);

    // ロックする check 時のヘルプコンテンツ表示
    this._initLockCheckboxHelp($b, type);

    console.log('SlideFormBasePartialView#render', this.name, type, this.md);
    return $b;
  }

  show() {
    if (!this.$b) return;
    // すぐ開きたいけど render 直後の場合だと
    // slide animate させたいので一瞬待つ
    setTimeout(() => {
      $('body').addClass('lock');
      this.$b.addClass('show');
    }, 100);
  }

  _getLabels(type) {
    const lblDef = {
      create: 'foo',
      update: 'bar',
    };
    return lblDef[type] || {};
  }

  _setDataToDom(md, type) {
    const {$b} = this;
    const cd = md.data || {}; // content data
    $b.find('[name=text]').text(cd.text);
    const $lock = $b.find('[name=locked]').prop('checked', cd.locked);
    const $lockLbl = $b.find('label._locked');

    // 編集モードで Lock は変更不可
    if (type === 'update') {
      $lock.prop('disabled', true);
      $lockLbl.addClass('disabled');
      $(`
        <input type="hidden" name="locked" value="${cd.locked ? '1' : ''}">
      `).appendTo($lockLbl);
    }

    // 登録プランによる差
    if (!this.c.currentUserHasSubscPlan()) {
      // 無料会員 (+未ログイン) は Lock 投稿できない
      $lockLbl.remove();
    }
  }

  _registerFormClose($closeBtn) {
    const {$b} = this;
    $closeBtn.on('click', () => {
      $b.removeClass('show');
      $('body').removeClass('lock');
      setTimeout(() => $b.remove(), 100);
      return false;
    });
  }

  _registerSubmit($closeBtn, type) {
    const {$b} = this;
    const label = type === 'create' ? '作成中' : '更新中';

    $b.find('button.submit').on('click', e => {
      this._clearError();
      const bh = new ButtonViewHelper($(e.target));
      bh.startLoading(label);

      const $form = $b.find('form:first');
      const d = this._sanitize($form.serializeJSON());
      this._runModelMethod(type, d)
        .then(r => {
          if (!r.success) {
            bh.stopLoading();
            if (r.error) this._displayError([r.error.message]);
            else throw new Error(r);
            return;
          }

          // model 実装に合わせて distType を手動セット (イケてないけど暫定対処)
          r.data.dispType = 'owner';

          console.log('#_registerSubmit ok', type, r.data);
          if (this.closeOnSubmitSuccess) {
            bh.stopLoading();
            $closeBtn.click();
          }
          $('body').trigger(`${this.name}:${type}:success`, [r.data]);
        })
        .catch(e => console.error('#_registerSubmit', type, e));
      return false;
    });
  }

  _runModelMethod(type, d) {
    console.log('#_runModelMethod', type, d);
    // return this.c.topic.create(d); // example
  }

  _sanitize(fd) {
    const d = {
      text: fd.text,
      locked: fd.locked ? true : false,
    };
    return d;
  }

  _clearError() {
    if (!this.$b) return;
    this.$b.find('p.error').remove();
  }

  _displayError(es) {
    if (!this.$b) return;
    console.log('#_displayError', es);
    const lbls = [];
    es.forEach(msg => {
      lbls.push(this.c.topic.errorLabelForMsg(msg));
    });
    $(`
      <p class="error">${lbls.join('、')}</p>
    `).insertAfter(this.$b.find('h3:first'));
  }

  _initLockCheckboxHelp($b, type) {
    // 新規作成フォームの時のみ
    if (type !== 'create') return;

    const $s = $(`
      <label style="display:none; padding-left:36px; font-size:0.8em; color:#888">
      文中に <em>---</em> <span style="font-size:0.7em">(ハイフン3つ)</span> を挿入する事で、
      以降が有料プラン会員のみ読めるようにロックをかけられます
      <a href="https://hack-thinking.zendesk.com/hc/ja/articles/900000368906#write" target="_blank">
      <i class="far fa-question-circle" style="font-size:1.2em"></i>
      </a>
      </label>
    `).insertAfter($b.find('label._locked'));

    $b.find('input[name=locked]').on('change', e => {
      const chk = $(e.target).prop('checked');
      if (chk) $s.fadeIn('fast');
      else $s.fadeOut('fast');
    });
  }
}
