import BasePartialView from 'js/view-partial/base';
import UrlViewHelper from 'js/view-helper/url';
import URLSearchParams from '@ungap/url-search-params';

export default class SwitchTabPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.$b = undefined; // render した dom
    this.defaultTabId = h.defaultTabId;
    this.tabs = h.tabs || [
      {
        id: 'topics',
        dom: 'ネタ',
        onSelect: () => {},
        onUnSelect: () => {},
      }, // sample
    ];
  }

  render() {
    const $b = $('<dl class="_switch-tab"></dl>');
    this.$b = $b;

    // tab 追加
    this._initTabs();

    // click 処理追加
    this._initOnClick();

    // 初期タブ表示
    this._showDefaultTab();

    // browser history back 処理予約
    this._registerPopState();
    return $b;
  }

  _initTabs() {
    this.tabs.forEach(t => {
      // DOM 追加
      const $tab = $(`
        <dd class="_${t.id}"><a class="_switch">${t.dom}</a></dd>
      `).appendTo(this.$b);
      t.$tab = $tab;
    });
  }

  _initOnClick() {
    const {$b} = this;
    $b.find('a._switch').on('click', e => {
      const $tab = $(e.delegateTarget).parent('dd:first');
      const [, id] = $tab.attr('class').match(/_(\w+)/) || [];
      if ($tab.hasClass('selected')) return false;

      this._showTab(id);
      this._pushState(id);
      return false;
    });
  }

  _showTab(id) {
    this.tabs.forEach(t => {
      if (t.id === id) {
        t.$tab.addClass('selected');
        t.onSelect();
      } else {
        t.$tab.removeClass('selected');
        t.onUnSelect();
      }
    });
  }

  _showDefaultTab() {
    // URL param 'tab=xxx' から判定
    const qId = this.c.qprm.get('tab');
    let {id} = this.tabs.find(r => r.id === qId) || {};

    // 無い場合は default 指定、あるいは 1 番目のタブを採用
    if (!id) {
      if (this.defaultTabId) id = this.defaultTabId;
      else id = this.tabs[0].id; // eslint-disable-line prefer-destructuring
    }

    this.tabs.forEach(t => {
      if (t.id !== id) return;
      setTimeout(() => this._showTab(id), 100);
    });
  }

  _pushState(tabId) {
    const p = new URLSearchParams(window.location.search);
    p.set('tab', tabId);
    let url = UrlViewHelper.currentUrl(true);
    url = `${url}?${p.toString()}`;
    window.history.pushState({tabId: tabId}, undefined, url);
  }

  _registerPopState() {
    window.addEventListener('popstate', e => {
      console.log('pop', e.state, e);
      const {tabId} = e.state || {};
      if (tabId) this._showTab(tabId);
      else this._showDefaultTab();
    });
  }
}
