import BaseView from 'js/view/base';
import FirebaseUtil from 'js/common/firebase-util';
import StatusPartialView from 'js/view-partial/status';

export default class AccountLoginView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('ログイン');
    this._render($m);
    console.log('#run done');
  }

  // private methods
  _render($m) {
    $(`
      <section class="login">
      <h1>ログイン</h1>
      <div id="firebaseui-auth-container"></div>
      <div style="margin-top: 30px; text-align:center;">
      <p style="font-size:0.7em; color:#888"><a href="/a/plan">
      アカウント登録</a>せずに Twitter でログインをした場合、無料プランのアカウントが登録されます。
      </p>
      </div>
      </section>
    `).appendTo($m);

    // status 表示
    const q = this.c.qprm;
    if (this.c.current_user && !q.get('force')) {
      const msg = '既にログイン済みです';
      StatusPartialView.show('caution', msg);
    } else if (q.get('required')) {
      // ログイン必須ページから自動遷移してきた場合
      StatusPartialView.show('caution', 'ログインが必要です');
    }

    // firebase UI を表示
    const fid = 'firebaseui-auth-container';
    const sUrl = this._getSuccessUrl();
    const onSuccess = () => {
      StatusPartialView.setFlash('success', 'ログインしました');
      sessionStorage.setItem('doUserSync', '1');
    };
    FirebaseUtil.loadLoginUi(`#${fid}`, sUrl, onSuccess);
  }

  _getSuccessUrl() {
    const ref = window.document.referrer;
    const ar = document.createElement('a');
    ar.href = ref;

    // 自サイトのログイン画面に来る前のページに遷移
    if (ref && ar.host === window.location.host) {
      console.log('ar', ar.pathname);
      if (ar.pathname === '/login') {
        const url = '/t';
        console.log('#_getSuccessUrl from login page', url);
        return url;
      } else if (ar.pathname === '/') {
        const url = '/t';
        console.log('#_getSuccessUrl from top page', url);
        return url;
      }
      console.log('#_getSuccessUrl from site page', ref);
      return ref;
    }

    // 外部サイト or 遷移前のページが無いなら default 遷移先
    const url = '/t';
    console.log('#_getSuccessUrl no ref or from ext site', url);
    return url;
  }
}
