import BaseModel from 'js/model/base';

export default class BaseItem extends BaseModel {
  _buildListParams(d = {}) {
    const h = {};
    ['limit', 'startAfter'].forEach(k => {
      if (d[k] !== undefined) h[k] = d[k];
    });

    if (d.type === 'owner' && d.owner) {
      h.wheres = [
        ['owner', '==', d.owner],
        ['state', 'in', ['deleted', 'active']],
      ];
      h.sort = [{orderBy: 'timestamp', direction: 'desc'}];
    } else if (d.type === 'profile' && d.owner) {
      h.wheres = [
        ['owner', '==', d.owner],
        ['state', 'in', ['active']],
      ];
      h.sort = [{orderBy: 'timestamp', direction: 'desc'}];
    } else if (d.type === 'like') {
      h.sort = [
        {orderBy: 'likeCount', direction: 'desc'},
        {orderBy: 'timestamp', direction: 'desc'},
      ];
    } else {
      h.sort = [{orderBy: 'timestamp', direction: 'desc'}];
    }
    return {query: h};
  }
}
