export default class BaseModel {
  // helper methods
  errorLabelForMsg(msg) {
    msg = msg.replace(/^#[^\s]+\s+/, ''); // 先頭の method comment を除外
    const def = {
      internal: 'サーバーまたは通信エラー',
      'requires login': 'ログインしてください',
    };
    return {label: def[msg], msg: msg};
  }

  // ここでは無く User model に居るべきだけど下の #_addDispTypeForCurrentUser
  // で使うのでやむなし
  hasSubscPlan(tickets = []) {
    return tickets.find(t => t.type === 'subsc-plan');
  }

  // private methods
  _runCallable(method, d = {}, rootNodeName = undefined) {
    const f = firebase
      .app()
      .functions('asia-northeast1')
      .httpsCallable(`api/v1${method}`);

    let r;
    return f(d)
      .then(_r => (r = _r))
      .then(() => {
        this._applyResponseCurrentUserDataToCurrentUser(r.data);
      })
      .then(() => {
        this._resolveUsers(r.data, r.data.users, rootNodeName);
        this._resolveParentPath(r.data, rootNodeName);
      })
      .then(() => {
        const ret = {success: true, data: r.data};
        console.log('#_runCallable success', ret);
        return ret;
      })
      .catch(e => {
        console.error('#_runCallable error', e.message);
        const ret = {success: false};
        if ((e.message || '').match('^ValidationError: (.+)')) {
          ret.error = {type: 'validation', message: RegExp.$1};
          return ret;
        } else {
          return Promise.reject(e.message);
        }
      });
  }

  _resolveUsers(data, users, rootNodeName = undefined) {
    if (!users) return;
    if (users instanceof Array === false) throw new Error('users is not array');

    if (!data) return;
    if (data instanceof Object === false) throw new Error('data is not object');

    let rows = rootNodeName === undefined ? data : data[rootNodeName];
    if (rows instanceof Array === false) rows = [rows];

    const ud = {};
    users.forEach(u => (ud[u.id] = u.data));
    rows.forEach(r => (r.user = ud[r.owner] || {}));
  }

  _resolveParentPath(data, rootNodeName = undefined) {
    if (!data) return;
    if (data instanceof Object === false) throw new Error('data is not object');

    let rows = rootNodeName === undefined ? data : data[rootNodeName];
    if (rows instanceof Array === false) rows = [rows];

    rows.forEach(r => {
      if (!r.data) return;
      const pp = r.data.parentPath;
      if (!pp) return;
      if (pp.match('Topic/([^/]+)')) r.topicId = RegExp.$1;
      if (pp.match('Comment/([^/]+)')) r.parentCommentId = RegExp.$1;
    });
  }

  // disp types
  // - owner: 自分の投稿ゆえ全文表示 + 編集可
  // - Lock 投稿の場合
  //   - locked-paid: 有料プラン user ゆえ全文表示
  //   - locked-free: 無料プラン user ゆえ有料プラン登録を促す
  //   - locked-non-login: 非ログイン user ゆえアカウント登録を促す
  // - 公開投稿の場合
  //   - open: 全文表示
  _addDispTypeForCurrentUser(data) {
    if (!data) return;
    if (data instanceof Object === false) throw new Error('data is not object');
    const rows = data instanceof Array === false ? [data] : data;

    const cu = firebase.auth().currentUser; // memo: イケてない
    const isLogin = cu ? true : false;
    const hasSubscPlan =
      isLogin && this.hasSubscPlan(cu.tickets) ? true : false;

    rows.forEach(r => {
      if (!r.data) return;
      let dt = 'open';
      if (cu && r.owner === cu.uid) {
        dt = 'owner';
      } else if (r.data.locked) {
        if (hasSubscPlan) dt = 'locked-paid';
        else if (isLogin) dt = 'locked-free';
        else dt = 'locked-non-login';
      }
      r.dispType = dt;
    });
  }

  _applyResponseCurrentUserDataToCurrentUser(data) {
    if (!data) return;

    const cu = firebase.auth().currentUser; // memo: イケてない
    if (!cu) return;

    let apiCu;
    if ((data.class || '') === 'User') apiCu = data;
    else apiCu = data.currentUser;
    if (!apiCu) return;

    ['screenName', 'picture', 'emailSend', 'tickets'].forEach(k => {
      cu[k] = apiCu.data[k];
    });
    console.log('#_applyApiResponseCurrentUserDataToCurrentUser done');
  }
}
