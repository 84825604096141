import EditMenuBasePartialView from 'js/view-partial/edit-menu/base';
import SlideFormCommentPartialView from 'js/view-partial/slide-form/comment';

export default class EditMenuCommentPartialView extends EditMenuBasePartialView {
  constructor(h = {}) {
    super(h);
    this.modelLabel = 'コメント';
    this.model = this.c.comment;
    this.name = 'comment';
  }

  _getSlideFormPartialView() {
    const opt = {c: this.c, md: this.md, topicId: this.md.topicId};
    return new SlideFormCommentPartialView(opt);
  }
}
