import BaseView from 'js/view/base';

export default class TopTosView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('利用規約');
    this._render($m);
    console.log('#run done');
  }

  // private methods
  _render($m) {
    $(`
      <section class="notes">

      <h1>ご利用規約</h1>
      <p align="center">発効日 : 2020年2月14日</p>

      <h3>第1条（適用範囲）</h3>
      <p>ハック思考利用規約（以下「本規約」といいます）は、bashika合同会社（以下「当社」といいます）が提供するサービス（以下「本サービス」といいます）をご利用いただく際の、当社と利用者との間の一切の関係に適用されます。利用者は本規約およびプライバシーポリシーに同意したうえで、自己の責任において本サービスを利用するものとします。なお、本サービスにおいて会員登録等の手続なく利用可能なサービスについては、当該サービスを利用することにより本規約およびプライバシーポリシーに同意したものとみなされます。</p>

      <h3>第2条（用語の定義）</h3>
      <ol>
      <li>「会員」とは、当社所定の方法により会員登録をした者をいいます。</li>
      <li>「利用者」とは、本サービスを利用する全ての者をいい、会員を含みます。</li>
      <li>「会員情報」とは、会員が会員登録、本サービスの利用において当社に対して開示した情報をいいます。</li>
      <li>「アカウント情報」とは、当社所定の方法による会員登録によって発行されるアカウントのID・パスワードをいいます。</li>
      <li> 「情報提供パートナー」とは、本サービスにおいて、利用者に情報を提供する者をいいます。</li>
      <li>「投稿データ」とは、利用者が本サービス上に投稿することで他の利用者に公開されるテキストデータならびに画像、動画、音声ファイルなどのマルチメディアデータを総称したものをいいます。</li>
      </ol>

      <h3>第3条（会員情報の登録）</h3>
      <ol>
      <li>会員は、会員情報については真正な情報を登録するものとし、その内容に変更が生じた場合には速やかに変更登録を行い、真正な状態を維持する義務を負うものとします。</li>
      <li>会員が、前項に定める義務を怠ったことにより生じた損害について、当社は一切責任を負わないものとします。</li>
      <li>当社は、当社のプライバシーポリシーに従い、会員情報を管理します。</li>
      <li>会員は、本条に定めるとおりに当社が会員情報を扱い、保有することに同意し、異議を申し立てないこととします。</li>
      </ol>

      <h3>第4条（アカウント情報の管理）</h3>
      <p>会員は、会員本人の責任の下でアカウント情報を管理するものとします。アカウント情報の盗用、不正利用その他の事情により会員以外の者が本サービスを利用した場合であっても、入力されたアカウント情報が登録されたものと一致することを当社所定の方法により確認したときは、当社は、当該会員による本サービスの利用があったものとみなし、当該会員に発生した損害について一切の責任を負わないものとします。</p>


      <h3>第5条（料金）</h3>
      <ol>
  <li>利用者は、当社の定める有料のコンテンツまたは機能を利用する場合は、当社の定める利用料金を当社の定める方法により当社の定める支払期日までに支払うものとします。また、当社は、理由の如何にかかわらず、別段の定めのない限り、既に支払われた利用料金を一切返還しません。</li>
  <li>利用者が当社の定める期日までに当社の定める利用料金を支払わなかった場合、利用者は、当社に対し、支払期日の翌日より年14.6%の割合による遅延損害金を支払うものとします。</li>
  <li>利用者は、利用料金を当社に代わって決済代行事業者が回収することに承諾するものとします。なお、当社は情報料の回収を決済代行事業者に委託しているため、利用者の支払情報または決済に関する情報を保有しておらず、かかる情報の取扱いまたは当社に起因しない決済に関する不具合等について一切の責任を負いません。</li>
  <li>利用料金には通信費･パケット接続料・インターネット接続料等は含まれておりません｡通信費・パケット接続料・インターネット接続料等については利用者各自の自己負担となります｡</li>
      </ol>

      <h3>第6条（投稿データの保証）</h3>
      <p>投稿データを投稿した利用者（以下「投稿者」といいます）は、自身の投稿データに関する一切の特許権、商標権、企業秘密、著作権その他の財産権および個人情報を含むプライバシー権、肖像権などの諸権利について保有するか、または、当社をして本規約に定められる投稿データの利用をさせるのに必要なライセンス、権利、同意および許可を有していることを保証するものとします。万一、当社と第三者との間で何らかの紛争が発生した場合には、投稿者の費用と責任において問題を解決するとともに、当社に何らの迷惑または損害を与えないものとします。</p>

      <h3>第7条（投稿データの権利）</h3>
      <ol>
      <li>当社は、投稿データを保存し、また、本条の定めに基づいて適宜利用することができるものとします。</li>
      <li>投稿データの著作権等の帰属等については次の各号に定めるとおりとします。
      <dl>
      <dt><span>（1）</span>著作者人格権（日本国著作権法第18条から第20条までにおいて定義される権利）については、投稿者は、自らまたは第三者をして、当社または当社の指定する第三者に対して行使せず、またはさせないものとします。</dt>
      <dt><span>（2）</span>著作権（日本国著作権法第21条から第28条までにおいて定義される権利）については、投稿者自身または原権利者に留保されます。その上で、投稿者は、当社に対し、非独占的に、世界中で投稿データの表示、編集、複製、転載、配布などを行い、かつ当社が有益であると判断した場合にはその内容を利用（出版、映像、翻訳、放送、演劇化などの派生著作物の作成および利用の場合を含む）することができるための無制限・無期限のライセンス（サブライセンスが可能であり、譲渡が可能なライセンス）を、無償で付与するものとします。</dt>
      </dl>
      </li>
      <li>当社は、第2項に定めるライセンスに基づき、投稿データの編集、複製、転載などを行い、当社が有益であると判断した場合にはその内容を利用（出版、映像、翻訳、放送、演劇化などの利用の場合を含む）することができ、当社および当社の指定する第三者に対しても利用させることができるものとします。これらの利用を行う場合でも、当社は投稿者に対し、何らの支払も要しないものとし、また、当社は投稿者のID、ニックネームその他の投稿者を表象する名称および情報を表示しないことができるものとします。</li>
      <li>投稿者が本サービスを退会した後も、当社は、第2項に定めるライセンスに基づき、当該投稿者が本サービス上に投稿した投稿データを利用することができるものとします。ただし、当該投稿者個人を特定する情報を表示しない措置を行った上で利用するものとします。</li>
      <li>投稿データの利用について、利用者および第三者に損害が発生したとしても、当社は一切責任を負わないものとします。</li>
      </ol>

      <h3>第8条（投稿データの変更および削除）</h3>
      <ol>
      <li>当社は、投稿データにつき次の各号に掲げる事由がある場合、当該投稿データを投稿した利用者の承諾を得ることなく、当該投稿データまたはその表示される内容を変更または削除することができるものとします。この場合、当社は、当社が被った損害を当該投稿データを投稿した利用者に賠償するよう請求することができるものとします。
      <dl>
      <dt><span>（1）</span>法令または本規約などに違反するものである場合</dt>
      <dt><span>（2）</span>第10条に定める禁止事項に該当する場合</dt>
      <dt><span>（3）</span>当社が直ちに変更または削除する必要があると判断した場合</dt>
      </dl>
      </li>
      <li>会員が本サービスを退会した後も、当該会員の投稿データは削除されるものではなく、当社において保存され、本サービス上または他のウェブサイトにおいて表示されるものとします。</li>
      <li>前二項の規定にかかわらず、当社は、本サービス内において利用者自身が任意に投稿データを変更、修正、または削除できるエリアを設けることができるものとします。また、当該エリアにおいては、当社が定める場合に、利用者が他の利用者の投稿を削除することができるものとし、投稿を変更、修正、削除された利用者は、当該投稿を変更、修正、削除した他の利用者および当社に対して異議を述べることができないものとします。</li>
      <li>当社は、利用者から、その利用者自身が投稿し、表示されている内容の変更、修正または削除の申し出を受けた場合でも、投稿し、表示されている内容の変更、修正および削除を行わないものとします。ただし、その内容が、前項に定めるエリア以外においてなされたものであって、かつ、その投稿し、表示されている内容が第１項各号に該当する場合を除きます。</li>
      </ol>

      <h3>第9条（情報の無断使用の禁止）</h3>
      <ol>
      <li>当社は、本規約に定めるものおよび本サービス上で明示的に認められているものを除き、利用者が本サービス上の文章、画像、イラストおよび動画などを含めた一切の情報を無断で使用することを禁止します。</li>
      <li>当社は、本サービスに関わる記載について、利用者が無断で編集、複製または転載することを禁止します。</li>
      <li>利用者が第1項または第2項に違反したために、当社が何らかの損害を被った場合、利用者は、当社に対して当該損害の賠償をしなければならないものとします。</li>
      </ol>

      <h3>第10条（禁止事項）</h3>
      <ol>
      <li>当社は、利用者が本サービスを利用するにあたり、次の各号に掲げる行為を禁止します。
      <dl>
      <dt><span>（1）</span>法令または公序良俗に反する行為</dt>
      <dt><span>（2）</span>犯罪的行為を助長、またはその実行を暗示する行為</dt>
      <dt><span>（3）</span>他の利用者、第三者または当社の知的財産権、肖像権、パブリシティ権などの正当な権利を侵害する、または侵害のおそれがある行為</dt>
      <dt><span>（4）</span>他の利用者、第三者または当社の財産、信用、名誉またはプライバシーを侵害する、または侵害のおそれがある行為</dt>
      <dt><span>（5）</span>他の利用者または第三者に無断で当該人物の個人を特定できる情報を公開する行為</dt>
      <dt><span>（6）</span>本サービスの提供される地域において法令に反する行為</dt>
      <dt><span>（7）</span>他の利用者または第三者に不利益または損害を与える行為</dt>
      <dt><span>（8）</span>他の利用者または第三者を誹謗中傷する行為</dt>
      <dt><span>（9）</span>当社が個別に許諾した場合を除き、営業や広告などに関する宣伝を目的にする行為</dt>
      <dt><span>（10）</span>過度または不適切に特定の外部ウェブサイトへ誘導することが目的と判断される行為</dt>
      <dt><span>（11）</span>児童・若年者に対し悪影響があると判断される行為</dt>
      <dt><span>（12）</span>違法・有害と判断されるアダルトサイト・出会い系サイトなどへのリンク行為</dt>
      <dt><span>（13）</span>アカウント情報を第三者に譲渡、貸与すること、または第三者と共用する行為</dt>
      <dt><span>（14）</span>本サービスの提供を妨げる行為</dt>
      <dt><span>（15）</span>本サービスおよび本サービスの関連アプリケーションソフトウェアについて、複製、改変、リバース・エンジニアリング、逆コンパイルまたは逆アセンブルをする行為</dt>
      <dt><span>（16）</span>当社が本サービスを提供するための設備（当社が本サービスを提供するために使用しまたは使用しようとする電子計算機その他の機器およびソフトウェアをいいます。以下同じ。）の正常な動作を妨げ、またはサービス用設備もしくはデータを破壊、損壊する行為</dt>
      <dt><span>（17）</span>コンピュータウイルス等有害なコンピュータプログラム等を、本サービスを通じてまたはこれに関連して、配布する行為</dt>
      <dt><span>（18）</span>本規約に違反する行為</dt>
      <dt><span>（19）</span>その他、当社が不適当と判断する行為</dt>
      </dl>
      </li>
      <li>前項各号に該当する行為によって当社が何らかの損害を被った場合、当社は、当該損害をその利用者に賠償するよう請求することができるものとします。</li>
      </ol>

      <h3>第11条（利用停止等）</h3>
      <ol>
      <li>当社は、利用者が次の各号のいずれかの事由に該当する場合には、事前に通知することなく、かつ、当該利用者の承諾を得ることなく、当該利用者による本サービスの利用停止、当該利用者のパスワードなどの変更、当該利用者の会員資格の取消しなど必要な措置をとることができるものとします。また、当社は、当該措置をとった理由を当該利用者に対して開示する義務を負わないものとします。
      <dl>
      <dt><span>（1）</span>本規約に違反した場合</dt>
      <dt><span>（2）</span>会員情報に虚偽があることが判明した場合</dt>
      <dt><span>（3）</span>アカウント情報を不正に使用した場合</dt>
      <dt><span>（4）</span>本サービスによって提供された情報を不正に使用した場合</dt>
      <dt><span>（5）</span>当社または第三者に損害を与える危険がある場合</dt>
      <dt><span>（6）</span>第10条に定める禁止行為を行った場合</dt>
      <dt><span>（7）</span>当社の定める有料のコンテンツまたは機能を利用する場合において、支払うべき利用料金の支払いをしない場合</dt>
      <dt><span>（8）</span>本規約を締結するための権利能力を有していない場合。</dt>
      <dt><span>（9）</span>利用者が日本国内に在住する自然人の場合、未成年者、成年被後見人、被保佐人、被補助人である場合</dt>
      <dt><span>（10）</span>利用者が日本国外に在住する自然人の場合、在住地の法令が定める成年年齢に達しておらず、かつ、本規約を単独で締結するために十分な事理弁識能力を有しておらず、権利能力について制限が付されている場合</dt>
      <dt><span>（11）</span>その他、当社が本サービスの利用について不適当と判断した場合</dt>
      </dl>
      </li>
      <li>当社が前項に定める措置をとったことにより会員に損害が発生した場合であっても、当社は一切責任を負わないものとします。</li>
      </ol>

      <h3>第12条（免責事項）</h3>
      <ol>
      <li>当社および情報提供パートナーは、本サービスによって提供する情報の正確性、完全性および安全性などを保証するものではありません。当該情報に起因して利用者および第三者に損害が発生したとしても、当社および情報提供パートナーは一切責任を負わないものとします。</li>
      <li>本サービスにおいて、情報提供パートナーが自己の名において提供する情報、表現および内容は、当該パートナーの選択と判断に基づくものであり、当社またはその従業員の意見や見解を反映したものではありません。</li>
      <li>当社は、本サービスに関し、他のインターネットサービス等と連携して一部の機能やサービスを提供することがあります。この場合において、本サービス上に投稿された内容が、一定条件の下、他のインターネットサービス等に公開される場合があることについて、利用者は、当社または当社の指定する第三者に対して異議を述べることができないものとします。</li>
      <li>本サービスはインターネットサービスであり、利用者は、本サービスの利用にあたっては、投稿の履歴および自己に関する情報の公開・非公開および投稿データについて、利用者自身の責任において管理するものとし、万が一利用者自身の選択による情報の公開または投稿に伴い損害が発生したとしても、当社は一切責任を負わないものとします。</li>
      <li>当社は、利用者に発生した使用機会の逸失、データの滅失、業務の中断、またはあらゆる種類の損害（間接損害、特別損害、付随損害、派生損害、逸失利益を含む）に対して、たとえ当社がかかる損害の可能性を事前に通知されていたとしても、一切責任を負わないものとします。</li>
      <li>当社は、利用者が本サービスの利用によって、他の利用者および第三者に対して与えた損害、ならびに利用者自身に生じた損害および紛争について、一切責任を負わないものとします。</li>
      <li>本サービスの提供を受けるためのインターネットへの接続は、利用者が自己の費用で行うものとし、当社は一切の費用および責任を負わないものとします。</li>
      </ol>

      <h3>第13条（本サービスの中断）</h3>
      <p>当社は、次の各号に掲げる事由がある場合、利用者に事前に連絡することなく、一時的に本サービスの提供を中断することがあります。本サービスの中断による損害について、当社は、一切責任を負わないものとします。
      <dl>
      <dt><span>（1）</span>当社のシステムの保守、点検、修理などを行う場合</dt>
      <dt><span>（2）</span>火災、停電または天災地変により本サービスの提供ができなくなった場合</dt>
      <dt><span>（3）</span>運用上または技術上、本サービスの提供ができなくなった場合</dt>
      <dt><span>（4）</span>その他、当社が中断をせざるを得ないと判断した場合</dt>
      </dl>
      </p>

      <h3>第14条（本サービスの追加・変更・停止・廃止）</h3>
      <ol>
      <li>当社は、本サービスの内容を、利用者への事前告知なく追加または変更することができます。</li>
      <li>当社は、本サービスを、利用者への事前告知なく停止または廃止することができるものとします。</li>
      <li>前二項に基づき利用者および第三者に損害が発生したとしても、当社は一切責任を負わないものとします。</li>
      </ol>

      <h3>第15条（本規約の変更）</h3>
      <p>当社は、利用者への事前告知なく、本規約を合理的な範囲内で変更することができるものとします。本規約が変更された場合、利用者は、本サービスの利用を継続することによって変更後の規約に同意したものとみなされ、当社と利用者との間の一切の関係には変更後の規約が適用されるものとします。</p>

      <h3>第16条（反社会的勢力の排除）</h3>
      <ol>
      <li>利用者は本サービスを利用するにあたって、自身が反社会的勢力（「暴力団員による不当な行為の防止等に関する法律」に定義する暴力団およびその関係団体をいうほか、総会屋、社会運動や政治運動を標ぼうするゴロ、特殊知能暴力団等、その他反社会的勢力の総称をいいます）ではないことを表明し、将来にわたっても、所属もしくは該当、または関与していないことを確約するものとします。</li>
      <li>当社は、利用者が反社会的勢力に所属もしくは該当する、または関与していると判断した場合、当該利用者に事前に通知等を行うことなく、当該利用者による本サービスの利用停止、当該利用者のパスワードなどの変更、当該利用者の会員資格の取消しなど必要な措置をとることができるものとします。</li>
      </ol>

      <h3>第17条（分離可能性）</h3>
      <p>本規約のいずれかの規定が、法令の改廃、特別法の適用、準拠法などの理由で、無効と判断された場合においても、本規約のその他の規定の有効性は、そのことにより一切影響を受けず、効力を維持するものとします。</p>

      <h3>第18条（準拠法）</h3>
      <p>本規約は、日本法に準拠して解釈されるものとします。</p>

      <h3>第19条（管轄裁判所）</h3>
      <p>本サービスに関して当社と利用者との間で紛争が生じた際には、日本国東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

      </section>
    `).appendTo($m);
  }
}
