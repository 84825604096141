import ActionMenuBasePartialView from 'js/view-partial/action-menu/base';
import HeartActionItemPartialView from 'js/view-partial/action-item/heart';
import PopUpMenuPartialView from 'js/view-partial/popup-menu';
import SlideFormCommentPartialView from 'js/view-partial/slide-form/comment';
import TextViewHelper from 'js/view-helper/text';
import UrlViewHelper from 'js/view-helper/url';

export default class ActionMenuTopicPartialView extends ActionMenuBasePartialView {
  render() {
    const $b = $(`
      <div class="icons">
        <em><i class="far fa-comment-dots actionable"></i><font></font></em>
        <em><a><i class="fab fa-twitter actionable"></i></a></em>
        <em><i class="fas fa-link actionable"></i></em>
      </div>
    `);
    this.$b = $b;

    // heart icon 挿入
    const ht = new HeartActionItemPartialView({
      c: this.c,
      parentView: this,
    });
    ht.render().prependTo($b);

    // 値を挿入
    this._setDataToDom(this.md);

    // click action 定義
    this._initActions();
    return $b;
  }

  _initActions() {
    super._initActions();
    const {$b} = this;

    // add comment button
    const dtp = this.md.dispType;
    if (!dtp.match(/locked-(free|non-login)/)) {
      $b.find('i.fa-comment-dots')
        .parent()
        .on('click', () => this._showNewItemForm());
    }

    // tweet button
    const turl = UrlViewHelper.urlFor('topic', 'detail', {id: this.md.id});
    const twUrl = UrlViewHelper.tweetUrlFor(turl);
    $b.find('i.fa-twitter')
      .parent('a')
      .attr('href', twUrl);

    // link button は popup menu を開く
    const lopt = {
      c: this.c,
      className: 'link',
      $opener: $b.find('i.fa-link'),
      def: [{action: 'copy-link', text: 'リンクをコピー'}],
    };
    const lpop = new PopUpMenuPartialView(lopt);
    lpop.setActionFor('copy-link', () => {
      TextViewHelper.copyToClipboard(turl);
      console.log('copy link to clipboard', turl);
    });
    lpop.render().appendTo($b);
  }

  _getSlideFormInstance() {
    const opt = {c: this.c, topicId: this.md.id};
    return new SlideFormCommentPartialView(opt);
  }
}
