import BaseController from 'js/controller/base';
import IndexView from 'js/view/subscription/index';
import CreateView from 'js/view/subscription/create';
import CancelView from 'js/view/subscription/cancel';
import Subscription from 'js/model/subscription';
import ScriptLoaderStripe from 'js/common/script-loader/stripe';
import User from 'js/model/user';

export default class SubscriptionController extends BaseController {
  index() {
    this.user = new User();
    this.subsc = new Subscription();
    return this._loadStripe().then(() => {
      return new IndexView({c: this}).run();
    });
  }

  create() {
    this.user = new User();
    return this._loadStripe().then(() => {
      return new CreateView({c: this}).run();
    });
  }

  cancel() {
    this.subsc = new Subscription();
    return new CancelView({c: this}).run();
  }

  // private methods
  _loadStripe() {
    return new ScriptLoaderStripe()
      .load()
      .then(() => {
        this.stripe = Stripe(process.env.STRIPE_API_KEY);
        this.subsc = new Subscription();
      })
      .catch(e => {
        console.error('#_loadStripe', e);
        throw new Error(e);
      });
  }
}
