import BaseView from 'js/view/base';
import ImageModalPartialView from 'js/view-partial/modal/image';
import LoadingPartialView from 'js/view-partial/loading';
import StatusPartialView from 'js/view-partial/status';
import ButtonViewHelper from 'js/view-helper/button';
import SampleImg from 'assets/imgs/account/resign/deleted.png';
import UserSegment from 'js/common/user-segment';

export default class AccountResignView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('アカウント削除');
    this._render($m);
    console.log('#run done');
  }

  // private methods
  _render($m) {
    const ld = new LoadingPartialView({c: this.c});
    ld.render().appendTo($m);

    return this.c.subsc.getActive().then(r => {
      console.log('subsc#getActive', r);
      ld.remove();

      // validation: 有効なサブスクがある場合は退会不可
      if (!this._validateSubsc(r.data)) {
        this._renderContent($m);
        return;
      }

      // validation: 直近 3 日以内にログインしてなかったら再ログイン促す
      // firebase auth 仕様 http://bit.ly/2VUuDeV
      if (!this._validateLastLogin()) {
        this._renderContent($m);
        return;
      }

      this._renderContent($m, true);
      console.log('#run done');
    });
  }

  _renderContent($s, activateBtn) {
    const $b = $(`
      <section class="signup">
      <h1>アカウント削除</h1>
      <p style="margin-bottom:1em">あなたのハック思考アカウント<br>を削除します。</p>
      <p style="margin-bottom:1em">アカウントを削除した場合でも、あなたが過去に投稿したコンテンツは削除されません。</p>
      <p>ただし、あなたが投稿した事は他人からは一切判らなくなります。以下退会後の投稿の表示例です:</p>
      <div>
        <a class="_show"><img src="${SampleImg}" width="140" style="margin:1em auto 2em auto"></a>
      </div>
      <p>以下ボタンをタップする事でアカウント削除が完了します。</p>
      <small>上記内容に同意の上、</small>
      <button type="button" class="_resign" disabled>アカウントを削除する</button>
      </section>
    `).appendTo($s);

    // 削除ボタン

    // image modal
    $b.find('a._show').on('click', () => {
      const mdl = new ImageModalPartialView({img: SampleImg});
      mdl.open();
      return false;
    });

    // delete button
    const $btn = $b.find('button._resign').on('click', () => {
      const ok = confirm('アカウントを削除します');
      if (ok) this._deleteAccount($btn);
    });
    if (activateBtn) $btn.prop('disabled', false);
  }

  _validateSubsc(md) {
    if (!md.id) return true;
    const msg = `
      契約中の有料プランがあります。<br><br>
      まずは<a href="/subs/cancel">こちらのページ</a>で契約を解除してください
    `;
    StatusPartialView.show('error', msg);
    return false;
  }

  _validateLastLogin() {
    const _lt = this.c.current_user.metadata.lastSignInTime;
    if (!_lt) throw new Error('current user lastSignInTime is blank');
    const lt = moment(_lt);
    const now = moment();
    let diffMinutes = now.diff(lt, 'minutes');

    // QA 用の特殊 get param
    const _dm = this.c.qprm.get('dm');
    if (_dm) diffMinutes = parseInt(_dm);
    console.log('diffMinutes', diffMinutes);

    if (diffMinutes <= 3) return true;

    const msg = `
      あなたである事を確認する為に<br>
      <a href="/login?force=1">再度こちらからログイン</a><br>
      をしてください
    `;
    StatusPartialView.show('caution', msg);
    return false;
  }

  _deleteAccount($btn) {
    const btn = new ButtonViewHelper($btn);
    btn.startLoading('削除中...');

    this.c.current_user
      .delete()
      .then(() => {
        // update user segment
        new UserSegment().updateForEvent('deleted');

        const msg = `
          アカウントが削除されました。
          ご利用ありがとうございました！
        `;
        StatusPartialView.setFlash('success', msg, true);
        window.location = '/?ad=1';
      })
      .catch(e => {
        console.error('#_deleteAccount', e);
        throw new Error(e);
      });
  }
}
