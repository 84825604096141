import BaseItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/base';

export default class InvoiceItemForListWithPagerPartialView extends BaseItemForListWithPagerPartialView {
  constructor(h = {}) {
    super(h);
    this.name = 'invoice';
  }

  render() {
    const $b = $(`
      <li>
      <span style="font-size:0.8em" class="_created">支払い日：<font></font></span>
      <span style="font-size:0.8em" class="_period">提供期間：<font></font></span>
      <span class="_status"><a>領収書を表示</a></span>
      </li>
    `);
    this.$b = $b;

    // 値を挿入
    this._setDataToDom(this.md);

    console.log(`${this.name} item#render`, this.md);
    return $b;
  }

  // private methods
  _setDataToDom(md) {
    const {$b} = this;

    const created = moment(md.created * 1000).format('YYYY/MM/DD');
    $b.find('._created font').text(created);

    const li = (md.lines.data || [])[0] || {};
    if (li) {
      const ps = moment(li.period.start * 1000).format('YYYY/MM/DD');
      const pe = moment(li.period.end * 1000).format('YYYY/MM/DD');
      $b.find('._period font').text(`${ps} - ${pe}`);
    }

    const $st = $b.find('._status');
    if (md.status === 'paid') $st.find('a').attr('href', md.hosted_invoice_url);
    else $st.text(md.status);
  }

  _makeContentLink() {}
}
