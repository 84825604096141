import BaseItem from 'js/model/item/base';

export default class Like extends BaseItem {
  list(d = {limit: 20}) {
    const p = this._buildListParams(d);
    return this._runCallable(`/like`, p, 'likedItems').then(r => {
      const d = r.data;
      this._addDispTypeForCurrentUser(d.likedItems, d.currentUser);
      return r;
    });
  }

  _buildListParams(d = {}) {
    const h = {};
    ['limit', 'startAfter'].forEach(k => {
      if (d[k] !== undefined) h[k] = d[k];
    });

    h.wheres = [['owner', '==', d.owner]];
    h.sort = [{orderBy: 'timestamp', direction: 'desc'}];
    return {query: h};
  }
}
