import BaseModalPartialView from 'js/view-partial/modal/base';

export default class ImageModalPartialView extends BaseModalPartialView {
  constructor(h = {}) {
    super(h);
    this.img = h.img;
    this.imgWidth = h.imgWidth;
  }

  open() {
    const $b = this._getDom();
    $b.plainModal('open', this.opt);
  }

  // private methods
  _getDom() {
    let $b = $('section._modal');
    if ($b.length > 0) return $b;

    $b = $(`<section class="_modal"></section>`);
    $b.appendTo('body');

    const w = this.imgWidth || $(document).width();
    $(`<img src="${this.img}" width="${w}">`)
      .on('click', () => $b.plainModal('close'))
      .appendTo($b);

    return $b;
  }
}
