import BasePartialView from 'js/view-partial/base';
import LoadingPartialView from 'js/view-partial/loading';
import PagerPartialView from 'js/view-partial/pager';
import StatusPartialView from 'js/view-partial/status';

export default class BaseListWithPagerPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.params = h.params || {}; // list api params
    this.showPaginate = h.showPaginate === undefined ? true : h.showPaginate;
    this.zeroLabel = h.zeroLabel || 'ゼロ件でした';
    this.showName = h.showName !== undefined ? h.showName : true;
    this.showItemTypeMark = h.showItemTypeMark || false;

    // define in sub classes
    this.domId = 'item-list';
    this.responseNodeName = '';
    this.listItemViewClass = undefined;
    this.getList = () => {};
  }

  render($b, pager = undefined) {
    // pager
    if (!pager) pager = this._initPager();
    pager.remove();

    // loading
    const lp = new LoadingPartialView();
    lp.render().appendTo($b);

    const param = pager.getParam();
    this.getList(param).then(r => {
      lp.remove(); // loading 消す
      if (!r.success) {
        StatusPartialView.show('error', 'サーバエラー', true);
        console.error('list#render api error', r);
        return;
      }

      // ゼロ件の場合
      const items = r.data[this.responseNodeName];
      if (items.length === 0) {
        $(`<p>${this.zeroLabel}</p>`).appendTo($b);
        return;
      }

      // 一覧表示
      const $ul = this._getListDom($b);
      items.forEach(itm => {
        const opt = {
          c: this.c,
          md: itm,
          showName: this.showName,
          showItemTypeMark: this.showItemTypeMark,
        };
        const v = this._getListItemView(opt);
        v.render().appendTo($ul);
      });

      // ページング UI
      if (this.showPaginate) {
        const renderNextPage = () => {
          this.render($b, pager);
        };
        const elems = pager.render(r.data, renderNextPage); // elems == [$a, $b]
        $b.append(elems); // appendTo([$a, $b]) は動かんので
      }
    });
  }

  _getListDom($b) {
    let $ul = $b.find(`#${this.domId}`);
    if ($ul.length === 0) $ul = $(`<ul id="${this.domId}"></ul>`).appendTo($b);
    return $ul;
  }

  _initPager() {
    const opt = $.extend(this._pagerOptions(), {
      params: this.params,
      responseNodeName: this.responseNodeName,
    });
    return new PagerPartialView(opt);
  }

  // define in sub classes
  _getListItemView() {}

  _pagerOptions() {
    return {};
  }
}
