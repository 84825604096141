import BasePartialView from 'js/view-partial/base';
import TextViewHelper from 'js/view-helper/text';
import UrlViewHelper from 'js/view-helper/url';

export default class UserPartialView extends BasePartialView {
  static nameLinkHtml(id, name) {
    const dnm = TextViewHelper.dispNameFor(name);
    if (!name) return `<a href="javascript:void(0);" class="_user">@退会</a>`;

    const url = UrlViewHelper.urlFor('user', 'profile', {id: id});
    return `<a href="${url}" class="_user">${dnm}</a>`;
  }

  static imgHtml(id, imgUrl, name) {
    const dnm = TextViewHelper.dispNameFor(name);
    if (!name) return $(''); // 退会者

    return `<img src="${imgUrl}" alt="${dnm}" class="_user">`;
  }
}
