import BaseListWithPagerPartialView from 'js/view-partial/list-with-pager/base';
import TopicItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/topic';

export default class TopicListWithPagerPartialView extends BaseListWithPagerPartialView {
  constructor(h = {}) {
    super(h);
    this.domId = 'topic-list';
    this.responseNodeName = 'topics';
    this.getList = param => {
      return this.c.topic.list(param);
    };
  }

  _getListItemView(opt) {
    return new TopicItemForListWithPagerPartialView(opt);
  }
}
