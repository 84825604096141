import BaseViewHelper from 'js/view-helper/base';

export default class ButtonViewHelper extends BaseViewHelper {
  constructor($btn) {
    super();
    this.$btn = $btn;
    this.orgLabel;
  }

  startLoading(text) {
    this.orgLabel = this.$btn.text();
    this.$btn
      .prop('disabled', true)
      .empty()
      .append(`<span class="loading"></span>${text}`);
  }

  stopLoading() {
    this.$btn
      .prop('disabled', false)
      .empty()
      .append(this.orgLabel);
  }
}
