import BasePartialView from 'js/view-partial/base';

export default class PopUpMenuPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.$b = undefined;
    this.className = h.className || 'link';
    this.$opener = h.$opener; // これを click したら開閉
    // 項目定義 (with sample)
    this.def = h.def || [
      {action: 'twee', text: 'Tweet する', url: 'https://...'},
      {action: 'copy-link', text: 'リンクをコピー'},
    ];
    this.actions = {};
  }

  render() {
    const $b = $(`<ul class="menu ${this.className} _popup"></ul>`);
    this.$b = $b;

    this.def.forEach(r => {
      const $itm = $(`<li><a data-action="${r.action}">${r.text}</a></li>`);
      if (r.url) $itm.find('a').attr('href', r.url);
      $itm.appendTo($b);
    });

    // menu 開閉 event 予約
    this._registerOpen();
    // menu 選択 event 予約
    this._registerMenuSelect();

    return $b;
  }

  setActionFor(actionKey, func) {
    this.actions[actionKey] = func;
  }

  _registerOpen() {
    const {$opener} = this;
    if (!$opener || $opener.length === 0) return;
    const {$b} = this;

    $opener.on('click', () => {
      if ($b.is(':visible')) $b.fadeOut('fast');
      else $b.fadeIn('fast');
    });

    this._hideOnClickOutside($opener, () => {
      $b.fadeOut('fast');
    });
  }

  _registerMenuSelect() {
    const {$b} = this;
    $b.find('a').on('click', e => {
      $b.fadeOut('fast');
      const $a = $(e.target);
      const actionKey = $a.attr('data-action');
      const func = this.actions[actionKey];
      if (typeof func === 'function') func();
      if ($a.attr('href')) return; // link click させたいので
      return false;
    });
  }
}
