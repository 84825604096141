import BasePartialView from 'js/view-partial/base';

export default class HeartActionItemPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.$b = undefined; // render した dom
    this.loading = false; // API request 中かどうか

    // md を拾う為に親の view instance を指定
    // view.md だけ渡すと、親 view 側で view.md を差し替えた時に
    // NG なのでこの形が必須
    if (!h.parentView) throw new Error('parentView must be passed');
    this.parentView = h.parentView;

    // click action を有効化する？
    this.mode = h.mode || 'actionable'; // or blank
  }

  render() {
    const $b = $(`
      <em><i class="far fa-heart"></i><font></font></em>
    `);
    this.$b = $b;

    this._setDataToDom();
    this._initActions();
    return $b;
  }

  _setDataToDom() {
    const {$b} = this;
    const {md} = this.parentView;
    const $ht = $b.find('i.fa-heart');
    $ht.next('font').text(md.likeCount);

    const cu = this.c.current_user;
    if (cu && this.mode === 'actionable' && md.owner !== cu.uid)
      $ht.addClass('actionable');
    else $ht.removeClass('actionable');

    if (md.likeByCurrentUser) $ht.removeClass('far').addClass('fas');
    else $ht.removeClass('fas').addClass('far');
  }

  _initActions() {
    const {md} = this.parentView;
    const cu = this.c.current_user;
    const $ht = this.$b.find('i.fa-heart');
    if (cu && this.mode === 'actionable' && md.owner !== cu.uid) {
      $ht.on('click', () => this._updateLike());
    } else if (!cu) {
      $ht.on('click', () => {
        // 未ログインユーザーはアカ登録を促す
        const msg =
          'いいねをするには無料アカウントの登録が必要です。登録をしますか？';
        const ok = confirm(msg);
        if (!ok) return false;
        window.location = '/a/plan';
      });
    }
  }

  _updateLike() {
    const {md} = this.parentView;

    // mode 次第で何もしない
    if (this.mode !== 'actionable') return;

    // loading 状態だったら何もしない
    if (this.loading) return;

    // loading 状態にする
    this._setLoadingStatus();

    const current = md.likeByCurrentUser;
    let model;
    if (md.class === 'Topic') {
      model = this.c.topic;
    } else if (md.class === 'Comment') {
      if (md.parentCommentId) model = this.c.childComment;
      else model = this.c.comment;
    } else {
      throw new Error(`#_updateLike unknown model: ${model}`);
    }

    console.log('like click', current, model);

    if (current === true) {
      // いいねを外す
      model.unlike(md.id).then(r => {
        this._unsetLoadingStatus();
        this._updateLikeModelData(r.data);
        console.log('unliked', md);
      });
    } else {
      // いいねをつける
      model.like(md.id).then(r => {
        this._unsetLoadingStatus();
        this._updateLikeModelData(r.data);
        console.log('liked', md);
      });
    }
  }

  _updateLikeModelData(md) {
    ['likeCount', 'likeByCurrentUser'].forEach(k => {
      this.parentView.md[k] = md[k];
    });
    this._setDataToDom();
  }

  _setLoadingStatus() {
    this.loading = true;

    const $i = this.$b.find('i');
    const farfas = this._getFarFasCss($i);
    $i.removeClass(`${farfas} fa-heart actionable`).addClass(
      'loading disabled'
    );
  }

  _unsetLoadingStatus() {
    this.loading = false;

    const $i = this.$b.find('i');
    const farfas = this._getFarFasCss($i);
    $i.addClass(`${farfas} fa-heart actionable`).removeClass(
      'loading disabled'
    );
  }

  _getFarFasCss($e) {
    const regexp = /(?:^|\s)(fa(?:r|s))(?:$|\s)/;
    if ($e.attr('class').match(regexp)) return RegExp.$1;
    return;
  }
}
