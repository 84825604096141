import BaseViewHelper from 'js/view-helper/base';

export default class UrlViewHelper extends BaseViewHelper {
  static currentUrl(withoutQuery = true) {
    let url = window.location.href;
    if (withoutQuery) url = url.replace(/(\?|#).+/, '');
    return url;
  }

  // c = controller
  // a = action
  static urlFor(c, a, _h = {}) {
    const wl = window.location;
    const h = Object.assign({id: undefined, withoutQuery: true}, _h);
    const pd = {
      topic: {
        detail: `/t/${h.id}`,
        list: '/t',
      },
      comment: {
        detail: `/t/${h.topicId}/c/${h.id}`,
      },
      childComment: {
        detail: `/t/${h.topicId}/cc/${h.id}`,
      },
      user: {
        profile: `/u/${h.id}`,
      },
    };
    const path = (pd[c] || {})[a];
    if (!path) return;
    return `${wl.protocol}//${wl.host}${path}`;
  }

  // url == url to include in tweet message
  static tweetUrlFor(url) {
    const topt = {url: url, hashtags: 'ハック思考'};
    return `https://twitter.com/intent/tweet?${$.param(topt)}`;
  }

  static twitterAccountUrlFor(screenName) {
    return `https://twitter.com/${screenName}`;
  }
}
