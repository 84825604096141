import BasePartialView from 'js/view-partial/base';
import ActionMenuCommentPartialView from 'js/view-partial/action-menu/comment';
import EditMenuCommentPartialView from 'js/view-partial/edit-menu/comment';
import ChildCommentListPartialView from 'js/view-partial/child-comment-list';
import ChildCommentListItemPartialView from 'js/view-partial/child-comment-list-item';
import PlanUpgradeButtonPartialView from 'js/view-partial/plan-upgrade-button';
import SlideFormChildCommentPartialView from 'js/view-partial/slide-form/child-comment';
import UserPartialView from 'js/view-partial/user';
import TextViewHelper from 'js/view-helper/text';
import UrlViewHelper from 'js/view-helper/url';
import WindowViewHelper from 'js/view-helper/window';

export default class CommentListItemPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.md = h.md || {}; // model data
    this.$b = undefined;
  }

  render() {
    const $b = $(`
      <section class="reply" id="c-${this.md.id}">
      <dl><dt class="_comment">
      <h4>
      <span><img src="" alt="" class="_user"></span>
      <a class="_user"></a><font class="_dt"></font>
      <i class="fas fa-lock"></i>
      </h4>
      <p class="_text"></p>
      </dt></dl>
      </section>
    `);
    this.$b = $b;

    // 値を挿入
    this._setDataToDom(this.md);

    // Action UI 追加
    this._addActionMenu();

    // comment の持ち主には Edit Menu 追加
    this._addEditMenu();

    // 返信を表示
    this._addChildCommentList();

    // 返信が追加された時の処理予約
    this._initOnChildCommentAdd();

    console.log('CommentListItemPartialView#render', this.md);
    return $b;
  }

  // private methods
  _setDataToDom(md) {
    const $b = this.$b.find('dt:first');
    const text = TextViewHelper.filterTextForDisp(md.data.text);
    const ud = md.user || {};
    const dt = moment(md.createdAt).fromNow();

    if (!md.data.locked) $b.find('i.fa-lock').remove();

    const $txt = $b.find('p._text');
    if (md.state === 'active') $txt.empty().append(text);
    else $txt.addClass('delete').text('このコメントは削除されました');

    const ulHtml = UserPartialView.nameLinkHtml(md.owner, ud.screenName);
    $b.find('a._user').replaceWith(ulHtml);

    const uImgHtml = UserPartialView.imgHtml(
      md.owner,
      ud.picture,
      ud.screenName
    );
    $b.find('img._user').replaceWith(uImgHtml);

    $b.find('font._dt')
      .empty()
      .append(dt);

    // 登録プランによる差
    const dtp = md.dispType;
    if (dtp.match(/locked-(free|non-login)/)) {
      const type = RegExp.$1;
      $txt.addClass('locked');
      if ($b.find('a._register').length === 0) {
        const linkUrl = UrlViewHelper.urlFor('comment', 'detail', {
          id: this.md.id,
          topicId: this.md.topicId,
        });
        const $up = new PlanUpgradeButtonPartialView({
          c: this.c,
          type: type,
          triggerUrl: linkUrl,
        });
        $up.render().insertAfter($txt);
      }
    }
  }

  _addActionMenu() {
    const opt = {c: this.c, md: this.md};
    const am = new ActionMenuCommentPartialView(opt);
    am.render().appendTo(this.$b.find('dt:first'));
  }

  _addEditMenu() {
    if (this.md.dispType !== 'owner') return;
    const {$b} = this;

    const opt = {
      c: this.c,
      md: this.md,
      $target: $b.find('dt:first'),
      addToDom: ($i, $ul) => {
        $b.find('h4').append($i, $ul);
      },
    };
    const em = new EditMenuCommentPartialView(opt);
    em.render();

    $('body').on('comment:update:success', (e, md) => {
      if (this.md.id !== md.id) return;
      this.md = md;
      this._setDataToDom(md); // 画面に更新内容を反映
    });

    $('body').on('comment:delete:success', (e, md) => {
      if (this.md.id !== md.id) return;
      $b.remove();
      console.log('削除しました');
    });
  }

  _addChildCommentList() {
    const copt = {
      c: this.c,
      mds: this.md.comments,
    };
    const $dl = this.$b.find('dl:first');
    new ChildCommentListPartialView(copt).render($dl);

    this._updateChildCommentListMiscUiParts();
  }

  _updateChildCommentListMiscUiParts() {
    const $dl = this.$b.find('dl:first');
    if ((this.md.comments || []).length === 0) {
      // 返信が無い場合は縦罫線 design を消す
      $dl.removeClass('comment');
    } else {
      // 縦罫線 design 出す
      $dl.addClass('comment');
      // 返信が 1 件以上あるなら "コメントする" ボタン表示
      this._addCommentBtn();
    }
  }

  _addCommentBtn() {
    if ((this.md.comments || []).length === 0) return;
    const {$b} = this;
    const $dd = $b.find('dd.comment');
    if ($dd.length > 0) return;

    $(`<dd class="comment"><button type="button">返信する</button></dd>`)
      .on('click', () => {
        const opt = {c: this.c, parentCommentId: this.md.id};
        const v = new SlideFormChildCommentPartialView(opt);
        v.render('create').appendTo('main');
        v.show();
      })
      .appendTo($b.find('dl:first'));
  }

  _initOnChildCommentAdd() {
    $('body').on('child-comment:create:success', (e, md) => {
      if (this.md.id !== md.parentCommentId) return;
      const {$b} = this;

      if (!this.md.comments) this.md.comments = [md];
      else this.md.comments.push(md);

      const opt = {c: this.c, md: md};
      const $itm = new ChildCommentListItemPartialView(opt).render();

      const $cc = $b.find('dd._child-comment:last');
      if ($cc.length === 0) $b.find('dt._comment').after($itm);
      else $cc.after($itm);

      this._updateChildCommentListMiscUiParts();
      WindowViewHelper.scrollTo($itm);
    });
  }
}
