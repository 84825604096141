import Cookies from 'js-cookie';
import WebFont from 'webfontloader';
import FirebaseUtil from 'js/common/firebase-util';
import Router from 'js/common/router';
import TopController from 'js/controller/top';
import User from 'js/model/user';

export default class MainScopeLogics {
  static setAdminCookie() {
    const [, v] = window.location.search.match(/(?:\?|&)admin=([^#&]+)/) || [];
    if (v === '0924') Cookies.set('admin', v, {expires: 365 * 10});
    else if (v === '0') Cookies.remove('admin');
  }

  static loadWebFont() {
    // https://github.com/typekit/webfontloader
    WebFont.load({google: {families: ['Noto+Sans+JP:400,700']}});
  }

  static waitFirebaseSDK() {
    // firebase SDK の初期化完了を待つ
    return FirebaseUtil.waitForSDK();
  }

  static resolveController(path) {
    const s = new Router().getControllerActionForPath(path);
    if (!s.c) return Promise.reject('404 controller not routed');
    if (!s.c[s.a]) {
      const an = s.a ? `#${s.a} ` : '';
      return Promise.reject(`404 action ${an}not defined for ${s.cname}`);
    }
    console.log('routed', s);
    return s;
  }

  static checkLogin(s) {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          s.c.current_user = user;
          user
            .getIdTokenResult()
            .then(r => (user.claims = r.claims))
            .then(() => this._doUserSyncIfNeeded()) // ここ非同期で発動
            .then(() => {
              console.log('#checkLogin is login user', user);
              resolve(s);
            })
            .catch(e => reject(e));
        } else {
          console.log('#checkLogin no login');
          if (s.requireLogin === true) return reject('require login');
          resolve(s);
        }
      });
    });
  }

  static runControllerAction(s) {
    return s.c[s.a]();
  }

  static handleError(e) {
    let a = 'serviceUnavailable';
    if (e instanceof Error) {
      a = 'serviceUnavailable';
    } else if (e.match(/^404/)) {
      a = 'notFound';
    } else if (e.match(/^require login/)) {
      console.log('redirect to login page');
      window.location = '/login?required=1';
      return;
    }
    console.error('rejected', e, a);
    const c = new TopController();
    return c[a]();
  }

  static _doUserSyncIfNeeded() {
    const k = 'doUserSync';
    const us = sessionStorage.getItem(k);
    sessionStorage.removeItem(k);

    if (!us) return;
    console.log('user#sync start');
    new User().sync().then(r => {
      console.log('user#sync done', r);
    });
  }
}
