import BaseItem from 'js/model/item/base';

export default class Comment extends BaseItem {
  list(d = {limit: 20}) {
    const p = this._buildListParams(d);
    return this._runCallable(`/comment`, p, 'comments').then(r => {
      const d = r.data;
      this._addDispTypeForCurrentUser(d.comments, d.currentUser);
      return r;
    });
  }

  create(tid, d = {}) {
    return this._runCallable(`/topic/${tid}/comment/create`, d);
  }

  update(cid, d = {}) {
    return this._runCallable(`/comment/${cid}/update`, d);
  }

  delete(cid) {
    return this._runCallable(`/comment/${cid}/delete`);
  }

  like(cid) {
    return this._runCallable(`/comment/${cid}/like`);
  }

  unlike(cid) {
    return this._runCallable(`/comment/${cid}/unlike`);
  }
}
