import BaseController from 'js/controller/base';
import ActivitiesView from 'js/view/account/activities';
import CreateView from 'js/view/account/create';
import LoginView from 'js/view/account/login';
import PlanView from 'js/view/account/plan';
import ResignView from 'js/view/account/resign';
import SettingsView from 'js/view/account/settings';
import ChildComment from 'js/model/item/child-comment';
import Comment from 'js/model/item/comment';
import Like from 'js/model/item/like';
import Subscription from 'js/model/subscription';
import Topic from 'js/model/item/topic';
import User from 'js/model/user';

export default class AccountController extends BaseController {
  create() {
    return new CreateView({c: this}).run();
  }

  login() {
    return new LoginView({c: this}).run();
  }

  plan() {
    this.user = new User();
    return new PlanView({c: this}).run();
  }

  settings() {
    this.user = new User();
    this.subsc = new Subscription();
    return new SettingsView({c: this}).run();
  }

  activities() {
    this.topic = new Topic();
    this.comment = new Comment();
    this.childComment = new ChildComment();
    this.like = new Like();
    return new ActivitiesView({c: this}).run();
  }

  resign() {
    this.subsc = new Subscription();
    return new ResignView({c: this}).run();
  }
}
