export default class ScriptLoaderBase {
  constructor() {
    this.src = '';
  }

  load() {
    return new Promise(resolve => {
      $.getScript(this.src, () => resolve());
    }).then(() => this._validate());
  }

  // override in sub class
  _validate() {
    return Promise.resolve();
  }
}
