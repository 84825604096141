import BaseModel from 'js/model/base';

export default class Subscription extends BaseModel {
  createSessionForNewSubsc(d = {type: 'upgrade'}) {
    return this._runCallable('/subscription/createSessionForNewSubsc', d);
  }

  createSessionForPaymentChange() {
    return this._runCallable('/subscription/createSessionForPaymentChange');
  }

  getActive() {
    return this._runCallable('/subscription/getActive');
  }

  getUser(d = {payment: undefined}) {
    return this._runCallable('/subscription/getUser', d);
  }

  cancel() {
    return this._runCallable('/subscription/cancel');
  }

  invoiceList(d = {limit: 3, after: undefined}) {
    return this._runCallable('/subscription/invoiceList', d);
  }

  errorLabelForMsg(msg) {
    const h = super.errorLabelForMsg(msg);
    if (h.label) return h.label;

    const def = {
      'valid ticket exists': '既に有料プラン契約中です',
      'active stripe subscription exists': '既に有料プラン契約中です',
      'stripe session create failed':
        'システムエラー<br>お手数おかけしますが、ページ下部のお問合せリンクにてご連絡ください',
    };
    return def[h.msg];
  }
}
