import BaseView from 'js/view/base';
import LoadingPartialView from 'js/view-partial/loading';
import TopicListWithPagerPartialView from 'js/view-partial/list-with-pager/topic';
import UserPartialView from 'js/view-partial/user';
import TextViewHelper from 'js/view-helper/text';
import UrlViewHelper from 'js/view-helper/url';

export default class UserProfileView extends BaseView {
  run() {
    const $m = super.run();

    const ld = new LoadingPartialView({c: this.c});
    ld.render().appendTo($m);

    return this.c.user
      .get(this.c.prm.id, {withItemCounts: true})
      .then(r => {
        console.log('user#get', r);
        ld.remove();
        const md = r.data;

        this._setTitle(md);
        this._renderBasicInfo($m, md);
        return md;
      })
      .then(() => this._renderTopicList($m))
      .then(() => console.log('#run done'));
  }

  // private methods
  _setTitle(md) {
    const name = TextViewHelper.dispNameFor(md.data.screenName);
    super._setTitle(`${name} のプロフィール`);
  }

  _renderBasicInfo($m, md) {
    const sname = md.data.screenName;
    const name = TextViewHelper.dispNameFor(sname);
    const twUrl = UrlViewHelper.twitterAccountUrlFor(sname);
    const uImgHtml = UserPartialView.imgHtml(md.id, md.data.picture, sname);
    const dt = moment(md.createdAt).format('YYYY/MM/DD');
    const cnt = md.itemCounts || {};

    $(`
      <section class="profile">
      <h1>
      <span>${uImgHtml}</span>
      <a href="${twUrl}" target="_blank">${name}
      <i class="fab fa-twitter actionable" style="font-size: 0.7em"></i></a>
      <small>${dt} 参加</small>
      </h1>
      <h3>アクティビティ</h3>
      <em><i class="fas fa-pen"></i><font>${cnt.topic}</font></em>
      <em><i class="fas fa-comment-dots"></i><font>${cnt.comment}</font></em>
      <em><i class="fas fa-heart"></i><font>${cnt.like}</font></em>
      </section>
    `).appendTo($m);
  }

  _renderTopicList($m) {
    const $s = $(`
      <section class="list">
      <h3>ネタ</h3>
      </section>
    `).appendTo($m);

    const prms = {type: 'profile', owner: this.c.prm.id, limit: 3};
    const tl = new TopicListWithPagerPartialView({
      c: this.c,
      zeroLabel: '熟考中',
      showName: false,
      params: prms,
    });
    tl.render($s);
  }
}
