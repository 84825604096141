import BaseController from 'js/controller/base';
import DetailView from 'js/view/topic/detail';
import ListView from 'js/view/topic/list';
import Topic from 'js/model/item/topic';
import Comment from 'js/model/item/comment';
import ChildComment from 'js/model/item/child-comment';

export default class TopicController extends BaseController {
  list() {
    this.topic = new Topic();
    this.comment = new Comment();
    return new ListView({c: this}).run();
  }

  detail() {
    this.topic = new Topic();
    this.comment = new Comment();
    this.childComment = new ChildComment();
    return new DetailView({c: this}).run();
  }

  // 問い詳細ページの permalink 種別 + model id を判定
  permalinkType() {
    const path = window.location.pathname;
    const h = {};
    if (path.match('^/t/[^/]+/cc/([^/]+)$')) {
      h.type = 'child-comment';
      h.id = RegExp.$1;
    } else if (path.match('^/t/[^/]+/c/([^/]+)$')) {
      h.type = 'comment';
      h.id = RegExp.$1;
    } else if (path.match('^/t/([^/]+)$')) {
      h.type = 'topic';
      h.id = RegExp.$1;
    }
    return h;
  }
}
