import BaseView from 'js/view/base';
import InvoiceListWithPagerPartialView from 'js/view-partial/list-with-pager/invoice';
import LoadingPartialView from 'js/view-partial/loading';
import StatusPartialView from 'js/view-partial/status';
import ButtonViewHelper from 'js/view-helper/button';

// payment icons https://bit.ly/2QCI9QZ
import VisaImg from 'assets/imgs/subscription/credit-cards/visa.png';
import MasterImg from 'assets/imgs/subscription/credit-cards/mastercard.png';
import AmexImg from 'assets/imgs/subscription/credit-cards/amex.png';
import DinersImg from 'assets/imgs/subscription/credit-cards/diners.png';
import JcbImg from 'assets/imgs/subscription/credit-cards/jcb.png';

export default class SubscriptionIndexView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('有料プラン状況確認');
    this._render($m);
  }

  // private methods
  _render($m) {
    const ld = new LoadingPartialView({c: this.c});
    ld.render().appendTo($m);

    this.c.user
      .me()
      .then(r => {
        console.log('user#me', r);
        ld.remove();
        this._renderPaymentChangeStatus();
        this._renderBasicInfo($m);
        this._renderDescInfo($m);
      })
      .then(() => {
        console.log('#run done');
      });
  }

  _renderPaymentChangeStatus() {
    // ステータス表示: 支払い方法の変更
    const sq = this.c.qprm.get('setup');
    if (sq === 'cancel') {
      // stripe からキャンセル戻りしてきた場合
      const msg = '変更をキャンセルしました';
      StatusPartialView.show('caution', msg);
    } else if (sq === 'success') {
      // 支払い方法の変更が成功してきた場合
      const msg = '支払い方法の変更が完了しました';
      StatusPartialView.show('success', msg);
    }
  }

  _renderBasicInfo($m) {
    const $s = $(`
      <section class="signup">
      <h1>契約状況</h1>
      <table>
      <tbody>
      <tr><th>プラン</th><td id="plan-label"></td></tr>
      </tbody>
      </table>
      </section>
    `).appendTo($m);

    let label;
    if (this.c.currentUserHasSubscPlan()) {
      // 有料プランの場合
      label = `
        有料プラン<br>
        <span style="color:#888;font-size:0.8em;line-height:1.4em;margin-top:5px;display:block">
        月額1,100円<span style="font-size:0.8em">(税込)</span>の定期支払い
        </span>
      `;
      this._addUpdateButtons($s);
      this._renderPaymentMethod($s);
    } else {
      // 無料プランの場合
      label = '無料プラン';
    }
    $s.find('#plan-label').html(label);
  }

  _renderDescInfo($m) {
    const $s = $(`
      <section class="payment">
      <h3>お支払い履歴</h3>
      </section>
    `).appendTo($m);

    // 支払い履歴
    const il = new InvoiceListWithPagerPartialView({
      c: this.c,
      zeroLabel: '支払い履歴がありません',
      params: {limit: 6},
    });
    il.render($s);
  }

  _addUpdateButtons($s) {
    const $btns = $(`
      <div>
        <button type="button" class="_change">支払い方法を変更</button>
        <button type="button" class="delete">プランを解約する</button>
      </div>
    `).appendTo($s);

    $btns.find('._change').on('click', e => {
      const $btn = $(e.delegateTarget);
      this._onPaymentChangeBtnClick($btn);
    });

    $btns.find('.delete').on('click', () => {
      window.location = '/subs/cancel';
    });
  }

  _onPaymentChangeBtnClick($btn) {
    console.log('#checkout begin');

    const bh = new ButtonViewHelper($btn);

    // エラー発生時の処理
    const onErr = msg => {
      bh.stopLoading();
      const lbl = this.c.subsc.errorLabelForMsg(msg);
      StatusPartialView.show('error', lbl, true);
    };

    // stripe 処理開始
    bh.startLoading('準備中');
    this.c.subsc
      .createSessionForPaymentChange()
      .then(r => {
        if (!r.success) return Promise.reject(r.error);
        const sid = r.data.session.id;
        console.log('#createSession', sid, r);
        return sid;
      })
      .then(sid => this.c.stripe.redirectToCheckout({sessionId: sid}))
      .then(res => {
        if (!res.error) return;
        console.error('#stripe checkout error', res.error);
        onErr(res.error);
      })
      .catch(e => {
        console.error('#checkout error', e);
        onErr(e);
      })
      .finally(() => console.log('#checkout end'));
  }

  _renderPaymentMethod($s) {
    const $b = $(`
      <tr>
      <th>支払い方法</th>
      <td></td>
      </tr>
    `).appendTo($s.find('tbody:first'));

    const ld = new LoadingPartialView({c: this.c, type: 'small'});
    ld.render().appendTo($b.find('td:first'));

    const imgDef = {
      visa: VisaImg,
      mastercard: MasterImg,
      amex: AmexImg,
      diners: DinersImg,
      jcb: JcbImg,
    };

    this.c.subsc.getUser({payment: true}).then(r => {
      const d = r.data || {};
      console.log('#_renderPaymentMethod data', d);
      const cd = d.paymentCard;
      ld.remove();
      if (!d.customerId || !cd) return;

      const img = imgDef[cd.brand];
      const brandHtml = img
        ? `<img src="${img}" style="height:20px; display:inline; vertical-align: bottom;">`
        : cd.brand;

      $(`
        <div>${brandHtml} ${cd.last4}</div>
        <div>${cd.expMonth.toString().padStart(2, '0')}/${cd.expYear}</div>
      `).appendTo($b.find('td:first'));
    });
  }
}
