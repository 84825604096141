'use strict';
import 'assets/css/common.css';
import M from 'js/common/main-scope-logics';
moment.locale('ja-JP'); // global init

// main scope
console.log('begin');
const path = window.location.pathname;

let s;
Promise.resolve() // begin promise chain
  .then(() => M.setAdminCookie())
  .then(() => M.loadWebFont())
  .then(() => M.waitFirebaseSDK())
  .then(() => M.resolveController(path))
  .then(_s => (s = _s))
  .then(() => M.checkLogin(s))
  .then(() => M.runControllerAction(s))
  .then(() => console.log('end'))
  .catch(e => M.handleError(e));
