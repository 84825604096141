import BaseListWithPagerPartialView from 'js/view-partial/list-with-pager/base';
import CommentItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/comment';
import ChildCommentItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/child-comment';

export default class CommentListWithPagerPartialView extends BaseListWithPagerPartialView {
  constructor(h = {}) {
    super(h);
    this.domId = 'comment-list';
    this.responseNodeName = 'comments';
    this.getList = param => {
      return this.c.comment.list(param);
    };
  }

  _getListItemView(opt) {
    if (opt.md.parentCommentId)
      return new ChildCommentItemForListWithPagerPartialView(opt);
    else return new CommentItemForListWithPagerPartialView(opt);
  }
}
