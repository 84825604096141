import BasePartialView from 'js/view-partial/base';

export default class LoadingPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.$b = undefined; // render した dom
    this.type = h.type || 'normal'; // or small
  }

  render() {
    const $b =
      this.type === 'small'
        ? $('<i class="loading"></i>')
        : $('<div class="loading"></div>');
    this.$b = $b;
    return $b;
  }

  remove() {
    if (!this.$b) return;
    this.$b.remove();
  }
}
