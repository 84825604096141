import BasePartialView from 'js/view-partial/base';
import ChildCommentListItemPartialView from 'js/view-partial/child-comment-list-item';
import WindowViewHelper from 'js/view-helper/window';

export default class ChildCommentListPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.mds = h.mds || [];
    this.cmntViews = [];
  }

  render($dl) {
    // ゼロ件の場合は何もしない
    const count = this.mds.length;
    if (count === 0) return;

    // 何件目まで隠しておくか判定
    let showFromIdx = 0;
    if (count > 5) showFromIdx = count - 5;

    // child comment permalink なら隠さない
    const pt = this.c.permalinkType();
    let permalinkMatch = false;
    if (pt.type === 'child-comment' && this._hasChildCommentWithId(pt.id)) {
      showFromIdx = 0;
      permalinkMatch = true;
    }

    this.mds.forEach((cmt, i) => {
      const opt = {c: this.c, md: cmt};
      const v = new ChildCommentListItemPartialView(opt);

      const show = showFromIdx <= i ? true : false;
      v.render(show).appendTo($dl);

      // 他の返信表示 UI を追加
      if (i + 1 === showFromIdx) this._insertShowUi($dl, showFromIdx);

      this.cmntViews.push(v);
    });

    // child comment permalink なら該当箇所に scroll
    if (permalinkMatch) {
      const $itm = $dl.find(`#cc-${pt.id}`);
      console.log('child comment permalink', $itm);
      setTimeout(() => {
        WindowViewHelper.scrollTo($itm, {block: 'start'});
      }, 300);
    }
  }

  // private methods
  _insertShowUi($dl, hiddenItemsCount) {
    $(`
      <dd class="_show">
      <a class="show">他の ${hiddenItemsCount} 件の返信を表示</a>
      </dd>
    `)
      .on('click', e => {
        $(e.target).hide();
        this.cmntViews.forEach(cv => cv.show());
        return false;
      })
      .appendTo($dl);
  }

  _hasChildCommentWithId(ccid) {
    return this.mds.find(itm => itm.id === ccid);
  }
}
