import BaseView from 'js/view/base';
import CommentListItemPartialView from 'js/view-partial/comment-list-item';
import CommentListPartialView from 'js/view-partial/comment-list';
import LoadingPartialView from 'js/view-partial/loading';
import SlideFormCommentPartialView from 'js/view-partial/slide-form/comment';
import TopicInfoPartialView from 'js/view-partial/topic-info';
import WindowViewHelper from 'js/view-helper/window';

export default class TopicDetailView extends BaseView {
  constructor(h = {}) {
    super(h);
    this.$bi = undefined; // topic info block
  }

  run() {
    const $m = super.run();

    const ld = new LoadingPartialView({c: this.c});
    ld.render().appendTo($m);

    this.c.topic.getWithComments(this.c.prm.id).then(r => {
      const {topic} = r.data;
      this._setTitle(topic.data.text);
      ld.remove();

      // ネタ情報表示
      const $bi = new TopicInfoPartialView({c: this.c, md: topic}).render($m);
      this.$bi = $bi;

      // コメント一覧表示
      const copt = {
        c: this.c,
        mds: topic.comments,
        hasCurrentUsersComment: topic.hasCurrentUsersComment,
      };
      new CommentListPartialView(copt).render($m);

      // コメントするボタン表示
      this._addCommentButtonSection($m, topic);

      // コメントが追加された時の処理予約
      this._initOnCommentAdd();
    });

    // 有料プラン登録時に保存しておいた元投稿 URL 情報を消す
    window.localStorage.removeItem('planUpgradeClickPage');

    console.log('#run done');
  }

  // private methods
  _setTitle(text) {
    const tl = (text || '').substr(0, 20);
    super._setTitle(`問: ${tl}`);
  }

  _addCommentButtonSection($m, md) {
    const cu = this.c.current_user;

    // 登録プランによる差
    const dtp = md.dispType;
    if (dtp.match(/locked-(free|non-login)/)) return;

    const $b = $(`
      <section class="comment">
      <button type="button"><i class="far fa-comment-dots"></i>コメントする</button>
      </section>
    `).appendTo($m);

    const $btn = $b.find('button:first');
    if (cu) {
      $btn.on('click', () => {
        const opt = {c: this.c, topicId: md.id};
        const v = new SlideFormCommentPartialView(opt);
        v.render().appendTo('body');
        v.show();
      });
    } else {
      $btn.attr('style', 'background:#DDD').on('click', () => {
        // 未ログインユーザーはアカ登録を促す
        const msg =
          'コメントするには無料アカウントの登録が必要です。登録をしますか？';
        const ok = confirm(msg);
        if (!ok) return false;
        window.location = '/a/plan';
      });
    }
  }

  _initOnCommentAdd() {
    $('body').on('comment:create:success', (e, md) => {
      const $itm = new CommentListItemPartialView({c: this.c, md: md}).render();
      const $rp = $('main').find('section.reply:last');

      if ($rp.length === 0) this.$bi.after($itm);
      else $rp.after($itm);
      WindowViewHelper.scrollTo($itm);
    });
  }
}
