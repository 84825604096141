import BaseView from 'js/view/base';

export default class TopLawView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('特定商取引法に基づく表記');
    this._render($m);
    console.log('#run done');
  }

  // private methods
  _render($m) {
    $(`
      <section class="notes">
      <h1>特定商取引法に基づく表記</h1>
      <table>
      <tbody>
      <tr><th>サービス名</th><td>ハック思考</td></tr>
      <tr><th>販売事業者名（会社名）</th><td>bashika 合同会社</td></tr>
      <tr><th>代表者名</th><td>代表社員 石橋 利真</td></tr>
      <tr><th>所在地</th><td>〒152-0023 東京都目黒区八雲4丁目8番18号  </td></tr>
      <tr><th>お問い合わせ</th><td>サイトのフッター部分にある "お問合せ" リンクからお問い合わせください。
      <small>※弊社電話番号はお客様からのご要望に基づき遅滞なく電子メールにて通知いたします。弊社電話番号が必要なお客様は上記フォームよりお申し出ください。</small></td></tr>
      <tr><th>販売価格</th><td>購入手続きの際に画面に表示されます。
      <small>※消費税は内税として表示しております。</small></td></tr>
      <tr><th>販売価格以外でお客様に発生する金銭</th><td>当サイトのページの閲覧等に必要となるインターネット接続料金、通信料金は、お客様のご負担となります。</td></tr>
      <tr><th>お支払方法</th><td>以下のいずれかのお支払方法をご利用いただけます。
      <ul>
      <li>各種クレジットカード</li>
      <li>その他、購入にかかる決済を代行する会社が提供するお支払方法</li>
      </ul></td></tr>
      <tr><th>商品購入方法</th><td>当サイトの "有料プランのご案内" ページから「有料プランに登録する」ボタンを押下し、各種お支払方法で決済していただきますと、有料会員向けサービスをご利用できるようになります。</td></tr>
      <tr><th>有料会員向けサービスの利用が可能となる時期</th><td>特別な定めを置いている場合を除き、お支払い手続き完了後直ちにご利用いただけます。</td></tr>
      <tr><th>返品・キャンセル</th><td>商品の性質上、ご購入後の返金・返品はできかねます。</td></tr>
      <tr><th>特別条件</th><td><ol>
      <li style="margin:0;"><b>クーリングオフについて</b>特定商取引法に規定されているクーリングオフが適用されるサービスではありません。
      <li><b>定期課金方式の注意事項</b>契約期間途中の解約となった場合も契約満了日までの料金が発生し、日割精算等による返金を含めた一切の返金は行われません。この場合、サービスも契約満了日まで提供されます。</li>
      </ol></td></tr>
      </tbody>
      </table>
      </section>
    `).appendTo($m);
  }
}
