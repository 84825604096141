import BaseView from 'js/view/base';

export default class TopPrivacyView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('プライバシーポリシー');
    this._render($m);
    console.log('#run done');
  }

  // private methods
  _render($m) {
    $(`
      <section class="notes">
      <h1>プライバシーポリシー</h1>
      <p>bashika合同会社（以下、当社といいます）は、個人情報に関する法令およびその他の規範を遵守するとともに、個人情報等を適切に管理するため、「プライバシーポリシー」（以下、本ポリシーといいます）を制定し、遵守することを宣言します。</p>
      <ol>
      <li>当社が個人情報等を直接的に取得する場合は、事前に利用目的、取得方法を明示し、ご本人の同意の上で取得を行います。ただし、取得時の状況から利用目的が明らかな場合は、利用目的の明示を省略することがあります。</li>
      <li>当社は、個人情報等の取り扱いにあたって、破壊、漏えい、滅失、改ざん、棄損の防止等の安全管理のため、技術面および組織面において合理的な安全対策措置および是正・予防措置を継続して講じます。</li>
      <li>当社は、法令に基づく場合、または、別途法令に基づく宣言を行った場合を除き、提供を受けた個人情報等をご本人の同意を得ることなく、第三者に開示または提供しません。</li>
      <li>当社は、個人情報等の取り扱いに関する苦情および相談については、個人情報等に関する苦情・相談窓口を設け、誠意を持って対応します。また、個人情報保護法に基づく個人情報等の開示、訂正、利用停止等の要請に対しては、当社にて本人確認ができた場合に、合理的な期間、妥当な範囲内でこれに応じます。</li>
      <li>当社が取得する個人情報等は、以下の通り取り扱います。</li>
      </ol>
      <h3>（1）個人情報の定義</h3>
      <p>本ポリシーにおいて、個人情報とは、生存する個人に関する情報であって、次の各号のいずれかに該当するものをいいます。</p>
      <ol>
      <li>当該情報に含まれる氏名、生年月日その他の記述等（文書、図画若しくは電磁的記録に記載され、若しくは記録され、又は音声、動作その他の方法を用いて表された一切の事項（個人識別符号を除く。）をいう。）により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む。）</li>
      <li>個人識別符号が含まれるもの</li>
      </ol>
      <h3>（2）取得する個人情報等の内容</h3>
      <p>当社は、氏名、ユーザー名、パスワード、郵便番号、住所、メールアドレス、性別、国籍、生年月日（年齢）、職業、趣味等の個人に関する情報、顧客番号、クッキー、IPアドレス、契約者・端末固有ID、利用環境、お客様が閲覧したページ等の情報等のインターネットの利用にかかるログ情報等を取得することがあります。これらの情報を総称して、個人情報等といいます。</p>
      <h3>（3）個人情報等の取得方法</h3>
      <p>当社がお客様の個人情報等を取得する方法は、以下のいずれかの方法となります。</p>
      <dl>
      <dd>①お客様ご自身で当社所定のウェブサイト上の画面または書面（電子メールを含む）に直接入力する方法</dd>
      <dd>②お客様ご自身が電話または対面によって口頭にて当社に伝達する方法</dd>
      <dd>③その他当社が指定する方法</dd>
      </dl>
      <p>なお、当社が運営するウェブ上で提供する、インターネットユーザーが利用可能なサービス（以下、当社サービスといいます）をお客様が利用する場合、個人情報等が、お客様のブラウザから自動的に送信され、各当社サービスのサーバに記録されます。</p>
      <h3>（4）個人情報等の利用目的</h3>
      <p>当社が取得する個人情報等は、以下の目的で使用するものとし、それ以外の目的では使用しません。以下の目的以外で使用する場合には、事前に通知します。</p>
      <h4>【当社サービスの利用者に関する個人情報等】</h4>
      <dl>
      <dd>①重要な通知やメンテナンス情報等当社サービスを提供する上でお客様に必要な通知をするため</dd>
      <dd>②特別なサービスおよびキャンペーン等の情報を的確に通知するため</dd>
      <dd>③利用状況、利用環境および投稿データ等に関する調査の実施や、個人を特定できない範囲での統計資料の作成および利用のため</dd>
      <dd>④お客様からのお問い合わせへの対応、本人確認および諸手続きのため</dd>
      <dd>⑤紛争・訴訟等への対応のため</dd>
      <dd>⑥その他サービス内容の充実を図るため</dd>
      </dl>
      <h4>【取引関係者に関する個人情報等】</h4>
      <dl>
      <dd>①当社サービスの提案、商談、交渉、契約の締結、契約の履行および業務上必要な連絡のため</dd>
      <dd>②取引先情報の管理のため</dd>
      </dl>
      <h3>（5）個人情報等の第三者への提供</h3>
      <p>当社は、以下に定める場合を除き、お客様の個人情報等を第三者に開示しません。</p>
      <ol>
      <li>お客様ご本人が事前に個人情報等の開示に同意した場合</li>
      <li>人の生命、身体および財産等に対する差し迫った危険があり、緊急の必要性がある場合</li>
      <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、お客様ご本人の同意を得ることが困難である場合</li>
      <li>裁判所や警察等の公的機関から、法律に基づく正式な照会を受けた場合</li>
      <li>その他の関係法令により当社による開示または提供義務が定められている場合</li>
      <li>当社サービス上でのお客様の行為が、利用規約、禁止事項、ルールおよびマナー等に反し、 当社サービスの権利、財産およびサービス等を保護するため、必要と認められる場合</li>
      </ol>
      <h3>（6）個人情報等の取り扱いの委託</h3>
      <p>当社は、利用目的の達成に必要な範囲内において、取得した個人情報等の取り扱いの全部または一部を委託する場合があります。その場合には、個人情報等の取り扱いに関し必要な契約を締結し、委託先に対し必要かつ適切な監督を行います。</p>
      <h3>（7）クッキーの使用</h3>
      <dl>
      <dd>①クッキーとは、ウェブサイトの運営に利用するサーバからお客様のブラウザに送信される個々のお客様の識別データであり、これらを使用することにより、お客様の情報をお客様のコンピューターのハードディスク等に記録することができます。多くのブラウザは、クッキーを受入れるように初期設定されており、多くのサイトで、お客様に有益な機能を提供する目的でクッキーが取得され、使用されています。クッキーにより、お客様の使用するコンピューターは特定されますが、お客様個人の身元を特定できるわけではありません。</dd>
      <dd>②当社サービスでは、お客様の個別のマイページへのログインおよびサイト上での全体傾向やパターン等の情報を取得するためにクッキーを取得しています。取得した情報は、当社がアクセス傾向を分析し、より良いサービスの提供および広告配信を行うために使用し、それ以外の目的には利用しません。また、そのことによりお客様のプライバシーまたは利用環境に影響を与えるものではありません。</dd>
      <dd>③お客様は、クッキーの受入れを拒否するように、または、クッキーが送信されていることを示すようにブラウザを再設定することもできますが、クッキーの受入れを拒否すると当社サービスの一部が正しく機能しない場合があります。</dd>
      </dl>
      <h3>（8）個人情報等に関するお客様の権利</h3>
      <dl>
      <dd>①個人情報等の処理がお客様の同意に基づくものである場合、お客様は、いつでもご自身の個人情報等に関する同意を取り下げる権利を有します。</dd>
      <dd>②お客様は、ご自身の個人情報等へのアクセスを求める権利、当社が保有するご自身の個人情報等の訂正を求める権利、ご自身の個人情報等を削除することを求める権利を有します。</dd>
      <dd>③上記権利の行使に、費用はかかりません。</dd>
      </dl>
      <h3>（9）お客様の権利行使に対する当社対応</h3>
      <p>当社は、前項に記載するお客様の権利を尊重し、お客様の要請に対し、ご本人からの要請であることの確認ができた場合に限り、合理的な期間、妥当な範囲内でこれに応じます。なお、お客様のご請求が明らかに根拠を欠いているか過剰である場合、特にご請求を繰り返されているような場合には、当社はお客様に合理的な費用を請求するか、お客様のご請求への対応をお断りさせていただく場合があります。</p>
      <h3>（10）個人情報等の削除</h3>
      <p>当社は、収集した個人情報等を、収集目的に必要な限度を超えて、保存することはありません。またお客様は、当社に対し、ご自身の個人情報等の削除をいつでも請求することができ、当社は個人情報等が、その収集目的又は処理目的に照らし必要でなくなった場合、個人情報等を速やかに削除いたします。</p>
      <h3>適用対象外</h3>
      <p>投稿データにリンクされている第三者のウェブサイト上で、個人情報等の入力が求められたり自動的に取得されたりすることがありますが、その場合、本ポリシーは適用されません。</p>
      <h3>プライバシーポリシーの変更</h3>
      <dl><dt><span>（1）</span> 当社は、お客様の事前の了承を得ることなく、本ポリシーを変更することができるものとします。本ポリシーに変更がある場合は、当社のホームページ上において通知します。</dt>
      <dt><span>（2）</span> 変更後のプライバシーポリシーは、当社が掲示した日付より効力を発するものとし、お客様が本ポリシーの変更後に当社が提供するサービスを利用することにより、本ポリシーの変更内容を承諾したものとみなします。</dt>
      </dl>
      <h3>お問い合わせ先</h3>
      <p>本ポリシーおよび個人情報等に関するお問い合わせは、サイトのフッター部分にあるお問い合わせリンクを利用してください。</p>
      </section>
    `).appendTo($m);
  }
}
