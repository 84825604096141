import BaseItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/base';
import EditMenuCommentPartialView from 'js/view-partial/edit-menu/comment';
import UrlViewHelper from 'js/view-helper/url';

export default class CommentItemForListWithPagerPartialView extends BaseItemForListWithPagerPartialView {
  constructor(h = {}) {
    super(h);
    this.name = 'comment';
    this.itemLabel = 'コメント';
    this.editMenuViewClass = EditMenuCommentPartialView;
  }

  // private methods
  _makeContentLink(md) {
    const h = {id: md.id, topicId: md.topicId};
    return UrlViewHelper.urlFor('comment', 'detail', h);
  }
}
