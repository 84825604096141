import BaseItem from 'js/model/item/base';

export default class ChildComment extends BaseItem {
  // pcid == parent comment id
  create(pcid, d = {}) {
    return this._runCallable(`/comment/${pcid}/comment/create`, d).then(r => {
      if (!r.success) return r;
      r.data.parentCommentId = pcid;
      return r;
    });
  }

  update(cid, d = {}) {
    return this._runCallable(`/comment/${cid}/update`, d);
  }

  delete(cid) {
    return this._runCallable(`/comment/${cid}/delete`);
  }

  like(cid) {
    return this._runCallable(`/comment/${cid}/like`);
  }

  unlike(cid) {
    return this._runCallable(`/comment/${cid}/unlike`);
  }
}
