import BasePartialView from 'js/view-partial/base';

export default class ActionMenuBasePartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.md = h.md || {}; // model data
    this.$b = undefined;
  }

  // private methods
  _setDataToDom(md) {
    const {$b} = this;

    const $cd = $b.find('i.fa-comment-dots');
    $cd.next('font').text(md.commentCount);
    //if (md.commentCount > 0) $cd.removeClass('far').addClass('fas');
    //else $cd.removeClass('fas').addClass('far');
    const cu = this.c.current_user;
    const dtp = this.md.dispType;
    if (cu && !dtp.match(/locked-(free|non-login)/)) $cd.addClass('actionable');
    else $cd.removeClass('actionable');
  }

  _showNewItemForm() {
    if (this.c.current_user) {
      const v = this._getSlideFormInstance();
      v.render().appendTo('body');
      v.show();
    } else {
      // 未ログインユーザーはアカ登録を促す
      const msg =
        'いいねをするには無料アカウントの登録が必要です。登録をしますか？';
      const ok = confirm(msg);
      if (!ok) return false;
      window.location = '/a/plan';
    }
  }

  _initActions() {}

  // override with sub class
  _getSlideFormInstance() {
    // example;
    // return new SlideFormCommentPartialView(opt);
    return;
  }
}
