import BasePartialView from 'js/view-partial/base';

export default class PagerPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.params = h.params || {}; // query params
    this.responseNodeName = h.responseNodeName || '';
    this.summaryType = h.summaryType || '';
    this.count = 0; // total items count
    this.current = 0; // current displayed items count
    this.hasMore = false; // has next page
    this.lastItemId = undefined; // API に渡す "現在最後の item id"
    this.$b = undefined;
  }

  render(data, renderNextPage) {
    this._update(data);
    const ret = [];

    let shtml = `<small>1-${this.current}／${this.count}件</small>`;
    if (this.summaryType === 'no-total')
      shtml = `<small>1-${this.current}件</small>`;

    ret.push($(shtml));
    if (this.hasMore) {
      const $btn = $(
        '<button type="button" id="pager-more">もっとみる</button>'
      ).on('click', () => renderNextPage());
      ret.push($btn);
    }
    this.$b = ret;
    return ret;
  }

  remove() {
    if (!this.$b) return;
    this.$b.forEach($e => $e.remove());
  }

  getParam() {
    const h = $.extend({}, this.params);
    if (this.hasMore) h.startAfter = this.lastItemId;
    return h;
  }

  _update(data) {
    const t = data[this.responseNodeName] || [];
    this.count = data.pager.count || 0;
    this.current = this.current + t.length;
    this.hasMore = data.pager.hasMore;
    this.lastItemId = t[t.length - 1].id;
  }
}
