import BaseView from 'js/view/base';
import LoadingPartialView from 'js/view-partial/loading';
import StatusPartialView from 'js/view-partial/status';
import ButtonViewHelper from 'js/view-helper/button';

export default class SubscriptionCreateView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('有料プラン契約');

    this._render($m);
    console.log('#run done');
  }

  // private methods
  _render($m) {
    const ld = new LoadingPartialView({c: this.c});
    ld.render().appendTo($m);
    this.c.user.me().then(() => {
      ld.remove();
      this._renderContent($m);
    });
  }

  _renderContent($m) {
    const $b = $(`
      <section class="signup">
      <h1>有料プランのご案内</h1>
      <p><a href="/a/plan#subsc">有料プラン</a>にアップグレードする事で、<i class="fas fa-lock"></i>アイコンのついたコンテンツを読む事が出来るようになります。</p>
      <dl>
      <dt>プラン料金</dt>
      <dd>月額<b>1,100</b>円<small>（税込）</small></dd>
      <dt>の定期支払い</dt>
      </dl>
      <p>
      <span>※契約完了した日から1ヶ月間の利用料金となります。</span>
      <span>※途中解約した場合の日割り払い戻しには対応しておりません。</span>
      <span>※支払い機能には外部サービス <a href="https://stripe.com/jp" target="_blank">Stripe</a> を利用しています。</span>
      <span>※支払いはクレジットカード, Apple Pay, Google Pay をご利用頂けます。</span>
      <span>※JCB, Diners Club, Discover カードはご利用頂けません。</span>
      <span>※特定商取引法に基づく表記は<a href="/law">こちら</a>。</span>
      </p>
      <small>上記契約内容に同意の上、</small>
      <button type="button" class="checkout">有料プランに登録する</button>
      </section>
    `).appendTo($m);

    // stripe からキャンセル戻りしてきた場合
    if (this.c.qprm.get('cancel')) {
      const msg = '登録をキャンセルしました';
      StatusPartialView.show('caution', msg);
    }

    // アカ登録から遷移してきた場合
    if (this.c.qprm.get('ac')) {
      const msg =
        'アカウント登録完了しました。続いて有料プランを登録してください';
      StatusPartialView.show('success', msg);
    }

    // 登録ボタン
    const $btn = $b.find('button.checkout:first');
    this._addCheckoutHandler($btn);

    // 既に有料プラン保持している場合
    if (this.c.currentUserHasSubscPlan()) {
      const msg = this.c.subsc.errorLabelForMsg('valid ticket exists');
      StatusPartialView.show('caution', msg);
      $btn.prop('disabled', true);
    }
  }

  _addCheckoutHandler($btn) {
    $btn.on('click', () => {
      console.log('#checkout begin');
      const bh = new ButtonViewHelper($btn);

      // エラー発生時の処理
      const onErr = msg => {
        bh.stopLoading();
        const lbl = this.c.subsc.errorLabelForMsg(msg);
        StatusPartialView.show('error', lbl, true);
      };

      // アカ登録と合わせての契約か？の判定
      const opt = {};
      const plan = window.localStorage.getItem('planSelect');
      opt.type = plan === 'paid' ? 'withAccountCreate' : 'upgrade';

      // stripe 処理開始
      bh.startLoading('準備中');
      this.c.subsc
        .createSessionForNewSubsc(opt)
        .then(r => {
          if (!r.success) return Promise.reject(r.error);
          const sid = r.data.session.id;
          console.log('#createSession', sid, r);
          return sid;
        })
        .then(sid => this.c.stripe.redirectToCheckout({sessionId: sid}))
        .then(res => {
          if (!res.error) return;
          console.error('#stripe checkout error', res.error);
          onErr(res.error);
        })
        .catch(e => {
          console.error('#checkout error', e);
          onErr(e);
        })
        .finally(() => console.log('#checkout end'));
    });
  }
}
