import ScriptLoaderFirebaseUi from 'js/common/script-loader/firebase-ui';
import UserSegment from 'js/common/user-segment';

export default class FirebaseUtil {
  static waitForSDK() {
    return new Promise(resolve => {
      $(() => {
        const app = firebase.app();
        const features = [
          'auth',
          'functions',
          'firestore',
          'performance',
        ].filter(f => typeof app[f] === 'function');
        console.log(`firebase: ${features.join(', ')}`);

        // local dev 環境の cloud functions 設定
        if (location.hostname.match('localhost|ngrok.io')) {
          firebase
            .app()
            .functions('us-central1')
            .useFunctionsEmulator('http://localhost:5001');
          firebase
            .app()
            .functions('asia-northeast1')
            .useFunctionsEmulator('http://localhost:5001');
        }

        resolve();
      });
    });
  }

  static loadLoginUi(dsel, url, onSuccess = () => {}) {
    // javascript library
    const success = res => {
      console.log('#loadLoginUi signin success', res);

      // update user segment
      const us = new UserSegment();
      us.updateForEvent('login', {
        isNewUser: (res.additionalUserInfo || {}).isNewUser,
      });

      onSuccess();
      return true;
    };

    const c = {
      signInFlow: 'popup',
      callbacks: {signInSuccessWithAuthResult: success},
      signInSuccessUrl: url,
      signInOptions: [firebase.auth.TwitterAuthProvider.PROVIDER_ID],
      tosUrl: '/tos',
      privacyPolicyUrl: () => {
        window.location.assign('/privacy');
      },
    };

    new ScriptLoaderFirebaseUi()
      .load()
      .then(() => {
        // via doc http://bit.ly/385kAXm
        const ui = new firebaseui.auth.AuthUI(firebase.auth());
        ui.start(dsel, c);
      })
      .catch(e => console.error('#loadLoginUi', e));
  }
}
