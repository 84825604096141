import BaseController from 'js/controller/base';
import IndexView from 'js/view/top/index';
import LawView from 'js/view/top/law';
import NotFoundView from 'js/view/top/not-found';
import PrivacyView from 'js/view/top/privacy';
import ServiceUnavailableView from 'js/view/top/service-unavailable';
import TosView from 'js/view/top/tos';
import Topic from 'js/model/item/topic';

export default class TopController extends BaseController {
  index() {
    this.topic = new Topic();
    return new IndexView({c: this}).run();
  }

  tos() {
    return new TosView({c: this}).run();
  }

  privacy() {
    return new PrivacyView({c: this}).run();
  }

  law() {
    return new LawView({c: this}).run();
  }

  notFound() {
    return new NotFoundView({c: this}).run();
  }

  serviceUnavailable() {
    return new ServiceUnavailableView({c: this}).run();
  }
}
