import BasePartialView from 'js/view-partial/base';
const storeKey = 'status-partial';

export default class StatusPartialView extends BasePartialView {
  // flash status 枠を表示する (例: http://bit.ly/32kPSaS)
  // type: 'success', 'caution', 'error'
  // $content: status 枠に表示させたい jQuery dom
  static show(type, $content, clear = false) {
    const $m = $('main:first');
    if ($m.length === 0) throw new Error('main div not found');

    // validation
    const td = ['success', 'caution', 'error'];
    if (!td.includes(type)) {
      console.error(`StatusPartialView#show unknown type: ${type}`);
      type = 'caution'; // 画面には caution で出しておく
    }

    // 既に status 枠があったら消す
    if (clear) {
      const $cs = $m.find('section.status');
      if ($cs.length > 0) {
        $cs.remove();
        console.log(`StatusPartialView#show cleared`);
      }
    }

    const $d = $(`
      <section class="status">
      <p class="${type}"></p>
      </section>
    `);
    $d.find('p:first').append($content);
    $d.prependTo($m);

    // scroll to top of the page
    $('html,body').animate({scrollTop: 0}, 'fast');
  }

  // 次の any ページ表示時に status を出すように予約する
  static setFlash(type, $content, clear = false) {
    const h = {type: type, $content: $content, clear: clear};
    const txt = JSON.stringify(h);
    window.sessionStorage.setItem(storeKey, txt);
    console.log('StatusPartialView#setFlash', h);
  }

  // any ページ表示時に予約された status を出す
  static showFlash() {
    const js = window.sessionStorage.getItem(storeKey);
    if (!js) return;
    const h = JSON.parse(js);
    this.show(h.type, h.$content, h.clear);
    window.sessionStorage.removeItem(storeKey);

    console.log('StatusPartialView#showFlash', h);
  }
}
