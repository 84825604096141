export default class BasePartialView {
  constructor(h = {}) {
    this.c = h.c; // controller instance
  }

  // 自分以外が click されたら閉じる処理を予約する
  _hideOnClickOutside($sel, handler) {
    // via https://stackoverflow.com/a/3028037
    const el = e => {
      const $tgt = $(e.target);
      if (!$tgt.closest($sel).length && $sel.is(':visible')) handler();
    };
    document.addEventListener('click', el);
  }
}
