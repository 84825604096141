import EditMenuBasePartialView from 'js/view-partial/edit-menu/base';
import SlideFormTopicPartialView from 'js/view-partial/slide-form/topic';

export default class EditMenuTopicPartialView extends EditMenuBasePartialView {
  constructor(h = {}) {
    super(h);
    this.modelLabel = 'ネタ';
    this.model = this.c.topic;
    this.name = 'topic';
  }

  _getSlideFormPartialView() {
    return new SlideFormTopicPartialView({c: this.c, md: this.md});
  }
}
