import BaseItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/base';
import EditMenuChildCommentPartialView from 'js/view-partial/edit-menu/child-comment';
import UrlViewHelper from 'js/view-helper/url';

export default class ChildCommentItemForListWithPagerPartialView extends BaseItemForListWithPagerPartialView {
  constructor(h = {}) {
    super(h);
    this.name = 'child-comment';
    this.itemLabel = '返信';
    this.editMenuViewClass = EditMenuChildCommentPartialView;
  }

  // private methods
  _makeContentLink(md) {
    const h = {id: md.id, topicId: md.topicId};
    return UrlViewHelper.urlFor('childComment', 'detail', h);
  }
}
