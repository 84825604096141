import BasePartialView from 'js/view-partial/base';
import ActionMenuChildCommentPartialView from 'js/view-partial/action-menu/child-comment';
import EditMenuChildCommentPartialView from 'js/view-partial/edit-menu/child-comment';
import PlanUpgradeButtonPartialView from 'js/view-partial/plan-upgrade-button';
import UserPartialView from 'js/view-partial/user';
import HilightFadeViewHelper from 'js/view-helper/hilight-fade';
import TextViewHelper from 'js/view-helper/text';
import UrlViewHelper from 'js/view-helper/url';

export default class ChildCommentListItemPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.md = h.md || {}; // model data
    this.$b = undefined;
  }

  render(show = true) {
    const $b = $(`
      <dd id="cc-${this.md.id}" class="_child-comment">
      <h4>
      <span><img class="_user"></span>
      <a class="_user"></a><font class="_dt"></font>
      <i class="fas fa-lock"></i>
      </h4>
      <p class="_text"></p>
      </dd>
    `);
    this.$b = $b;

    // 最初は隠しておく？
    if (!show) $b.hide();

    // 値を挿入
    this._setDataToDom(this.md);

    // Action UI 追加
    this._addActionMenu();

    // comment の持ち主には Edit Menu 追加
    this._addEditMenu();

    console.log('ChildCommentListItemPartialView#render', this.md);
    return $b;
  }

  // 非表示コメントを表示させる
  show() {
    const {$b} = this;
    if ($b.is(':visible')) return;
    $b.show();
    HilightFadeViewHelper.hilight($b);
  }

  // private methods
  _setDataToDom(md) {
    const {$b} = this;
    const text = TextViewHelper.filterTextForDisp(md.data.text);
    const ud = md.user || {};
    const dt = moment(md.createdAt).fromNow();

    if (!md.data.locked) $b.find('i.fa-lock').remove();

    const $txt = $b
      .find('p._text')
      .empty()
      .append(text);

    const ulHtml = UserPartialView.nameLinkHtml(md.owner, ud.screenName);
    $b.find('a._user').replaceWith(ulHtml);

    const uImgHtml = UserPartialView.imgHtml(
      md.owner,
      ud.picture,
      ud.screenName
    );
    $b.find('img._user').replaceWith(uImgHtml);

    $b.find('font._dt')
      .empty()
      .append(dt);

    // 登録プランによる差
    const dtp = md.dispType;
    if (dtp.match(/locked-(free|non-login)/)) {
      const type = RegExp.$1;
      $txt.addClass('locked');
      if ($b.find('a._register').length === 0) {
        const linkUrl = UrlViewHelper.urlFor('childComment', 'detail', {
          id: this.md.id,
          topicId: this.md.topicId,
        });
        const $up = new PlanUpgradeButtonPartialView({
          c: this.c,
          type: type,
          triggerUrl: linkUrl,
        });
        $up.render().insertAfter($txt);
      }
    }
  }

  _addActionMenu() {
    const opt = {c: this.c, md: this.md};
    const am = new ActionMenuChildCommentPartialView(opt);
    am.render().appendTo(this.$b);
  }

  _addEditMenu() {
    if (this.md.dispType !== 'owner') return;
    const {$b} = this;

    const opt = {
      c: this.c,
      md: this.md,
      $target: $b,
      addToDom: ($i, $ul) => {
        $b.find('h4').append($i, $ul);
      },
    };
    const em = new EditMenuChildCommentPartialView(opt);
    em.render();

    $('body').on('child-comment:update:success', (e, md) => {
      if (this.md.id !== md.id) return;
      this.md = md;
      this._setDataToDom(md); // 画面に更新内容を反映
    });

    $('body').on('child-comment:delete:success', (e, md) => {
      if (this.md.id !== md.id) return;
      $b.remove();
      console.log('削除しました');
    });
  }
}
