import BaseItemForListWithPagerPartialView from 'js/view-partial/list-with-pager/item/base';
import EditMenuTopicPartialView from 'js/view-partial/edit-menu/topic';
import UrlViewHelper from 'js/view-helper/url';

export default class TopicItemForListWithPagerPartialView extends BaseItemForListWithPagerPartialView {
  constructor(h = {}) {
    super(h);
    this.name = 'topic';
    this.itemLabel = 'ネタ';
    this.editMenuViewClass = EditMenuTopicPartialView;
  }

  // private methods
  _makeContentLink(md) {
    return UrlViewHelper.urlFor('topic', 'detail', {id: md.id});
  }
}
