import Cookies from 'js-cookie';
import URLSearchParams from '@ungap/url-search-params';

export default class BaseController {
  constructor(h = {}) {
    this.prm = h.prm || {};
    this.current_user = undefined;
    this.qprm = new URLSearchParams(window.location.search);
  }

  findCurrentUserTicket(type) {
    const cu = this.current_user;
    if (!cu) return;
    return (cu.tickets || []).find(t => t.type === type);
  }

  currentUserHasSubscPlan() {
    return this.findCurrentUserTicket('subsc-plan');
  }

  isAdminBrowser() {
    const v = Cookies.get('admin');
    return v === '0924' ? true : false;
  }

  isProjectEnv(env) {
    if (!env) throw new Error('env is required');
    if (env.match(/^(production|prd|prod)$/)) env = 'prd';
    return env === process.env.PROJECT_ENV ? true : false;
  }

  hideIfNotAdmin() {
    if (this.isAdminBrowser()) return false;
    return true;
  }
}
