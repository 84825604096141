export default class UserSegment {
  constructor() {
    this.skey = 'userseg';
    this.data = this._load();
  }

  updateForEvent(event, h = {}) {
    const d = this.data;
    let save = false;
    if (event === 'login') {
      d.account = 'created';
      if (!d.plan) d.plan = 'free';
      d.login = 'login';
      if (h.isNewUser) d.accountCreatedAt = moment().unix();
      save = true;
    } else if (event === 'logout') {
      d.login = '';
      save = true;
    } else if (event === 'deleted') {
      d.account = 'deleted';
      d.plan = '';
      d.login = '';
      d.accountDeletedAt = moment().unix();
      save = true;
    } else if (event === 'upgrade-to-subsc-plan') {
      d.plan = 'subsc';
      d.planUpgradedAt = moment().unix();
      save = true;
    } else if (event === 'downgrade-to-free-plan') {
      d.plan = 'free';
      d.planDowngradedAt = moment().unix();
      save = true;
    }
    return save ? this.save() : false;
  }

  save() {
    try {
      if (this.data === undefined) return false;
      const json = JSON.stringify(this.data);
      window.localStorage.setItem(this.skey, json);
      return true;
    } catch (e) {
      console.error('#save', e);
      return false;
    }
  }

  // private methods
  _load() {
    const init = {
      account: '',
      plan: '',
      login: '',
    };
    try {
      const d = window.localStorage.getItem(this.skey);
      return JSON.parse(d) || init;
    } catch (e) {
      console.error('#_load', e);
      return init;
    }
  }
}
