import BaseView from 'js/view/base';
import FirebaseUtil from 'js/common/firebase-util';
import LabelViewHelper from 'js/view-helper/label';
import StatusPartialView from 'js/view-partial/status';

export default class AccountCreateView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('アカウント登録');
    this._render($m);
    console.log('#run done');
  }

  // private methods
  _render($m) {
    const pid = localStorage.getItem('planSelect');
    const plb = LabelViewHelper.planById(pid) || 'プラン未選択';

    const $b = $(`
      <section class="signup">
      <h1>アカウント登録</h1>
      <p>ハック思考のアカウントは、あなたの Twitter アカウントによるログイン認証を行う事で登録されます。</p>
      <dl>
      <dt>${plb}</dt>
      </dl>
      <p>
      <span>※ハック思考が、あなたに替わって tweet を自動投稿するような事はありません。</span>
      <span>※メールアドレス／パスワードによるアカウント登録には対応していません。</span>
      </p>
      <div id="firebaseui-auth-container"></div>
      </section>
    `).appendTo($m);

    // validation
    if (!pid) {
      const msg = `
        登録に必要な情報が足りませんでした。
        <a href="/a/plan">こちらのプラン選択</a>から再度お試しください。
      `;
      StatusPartialView.show('error', msg);
      return;
    }

    // 既にログイン中の場合
    if (this.c.current_user) {
      const msg = '既にアカウント登録済みです';
      StatusPartialView.show('caution', msg);
      $(`
        <button disabled>登録済み</button>
        <p>&nbsp;</p>
      `).appendTo($b);
      return;
    }

    // firebase UI で twitter アカ登録 U/I を表示
    const fid = 'firebaseui-auth-container';
    // 登録成功時の遷移先
    const sUrl = pid === 'paid' ? '/subs/create?ac=1' : '/t?ac=1';
    FirebaseUtil.loadLoginUi(`#${fid}`, sUrl);
  }
}
