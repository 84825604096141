import BaseController from 'js/controller/base';
import ProfileView from 'js/view/user/profile';
import Topic from 'js/model/item/topic';
import User from 'js/model/user';

export default class UserController extends BaseController {
  profile() {
    this.topic = new Topic();
    this.user = new User();
    return new ProfileView({c: this}).run();
  }
}
