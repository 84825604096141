import BasePartialView from 'js/view-partial/base';
import UrlViewHelper from 'js/view-helper/url';

export default class PlanUpgradeButtonPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.type = h.type || 'non-login'; // or 'free'
    this.disp = h.disp || 'standard'; // or 'more'
    this.sKey = 'planUpgradeClickPage';
    // どのコンテンツのボタンを押したか
    this.triggerUrl = h.triggerUrl;
  }

  render() {
    let lbl = '有料会員登録して読む';
    let link = '/a/plan';
    if (this.type === 'free') {
      lbl = '有料プランにして読む';
      link = '/subs/create?up=1';
    }

    const $b = $(`
      <a href="${link}" class="locked _register">
      <i class="fas fa-lock"></i>${lbl}</a>
    `);
    this._setOnClick($b);

    if (this.disp === 'more') {
      let txt = '有料会員に登録する';
      if (this.type === 'free') txt = '有料プランにアップグレードする';
      const $more = $(`
        <p class="explain">このネタは${txt}事で続きが読めるようになります。
        <a href="/a/plan">詳しくはこちら</a>
        </p>
      `);
      this._setOnClick($more.find('a'));
      const $w = $('<div class="_upgrade"></div>');
      $w.append($b, $more);
      return $w;
    }

    return $b;
  }

  _setOnClick($b) {
    let url = this.triggerUrl;
    if (!url) url = UrlViewHelper.currentUrl(true);
    $b.on('click', () => {
      window.localStorage.setItem(this.sKey, url);
    });
  }
}
