import BaseView from 'js/view/base';
import TopicListWithPagerPartialView from 'js/view-partial/list-with-pager/topic';
import heroImg from 'assets/imgs/index/logo.svg';
import bookImg from 'assets/imgs/index/book.png';
import amznBookImg from 'assets/imgs/index/book-m.png';

export default class TopIndexView extends BaseView {
  run() {
    const $m = super.run();
    this._renderIndex($m);
    this._renderSampleList($m);
    this._renderNewList($m);
    this._renderBookOffer($m);
    console.log('#run done');
  }

  // private methods
  _renderIndex($m) {
    $(`
      <section class="index">
      <h1><img src="${heroImg}" alt="ハック思考 HACK THINKING"></h1>
      <h2>世界が変わる方法論をみんなで共有しよう！</h2>
      <p>オープンに共有しづらいハックのネタを会員だけに共有する Twitter 連動型のオンラインサロンです。<br>
      今すぐ役に立つハックの引き出しを皆で共有しましょう！</p>
      <a class="button _register" href="/a/plan">会員登録はこちら</a>
      </section>
    `).appendTo($m);

    $(`
      <section class="contents">
      <h3>みなさま、はじめまして。</h3>
      <p>須藤憲司と申します。周りの皆さんからはスドケンと呼ばれています。<br>
      幻冬舎の NewsPicks Book から『ハック思考』が発売されました。<br>
      言わずと知れた天才編集者の箕輪厚介さんと大ベストセラー『ビリギャル』の坪田信貴さんと一緒につくった本です。</p>
      <blockquote class="twitter-tweet"><p lang="ja" dir="ltr">この度、KAIZEN PLATFORM の須藤憲司 CEO の本を幻冬舎の箕輪厚介さんが編集して出すことになりました。僕はプロデュース頑張ります。初回の打ち合わせが終わって、これは面白い本になるぞと思いました。ネクスト孫正義とも言われる須藤社長。ここから更なる大躍進が始まりそうです。要チェックやっ！ <a href="https://t.co/I66oWPI8zC">pic.twitter.com/I66oWPI8zC</a></p>&mdash; 坪田信貴（ビリギャル 著者） (@NobutakaTsubota) <a href="https://twitter.com/NobutakaTsubota/status/1112661760853463040?ref_src=twsrc%5Etfw">April 1, 2019</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
      <p>発売前から Amazon をハックして総合ランキング2位にしてみたり、出版ルールのギリギリをついてタイトルを逆さまにして店頭をハックしてみたり、とにかくこの本そのものを使って色々なハックをしてきました。<br>
      そして、この本の帯を見てください。「膨大なハックのネタ帳です。」と書いてありますが、今もハックの引き出しを<a href="https://twitter.com/search?q=%23%E3%83%8F%E3%83%83%E3%82%AF%E6%80%9D%E8%80%83&f=live" target="_blank">#ハック思考</a>でつぶやき続けています。</p>
      <p><a href="https://amzn.to/38uMhss" target="_blank"><img src="${bookImg}" alt="ハック思考"></a></p>
      <p>そのハックの引き出しを皆で集めた方がいいじゃないか！という事で、有志で Twitter では公開しづらいハックの引き出しや相談を、クローズド <i class="fas fa-lock"></i> にしながら相談しあえるハック思考のオンラインサロンをつくりました。<br>
      ライトなネタからディープな話題まで、皆でハック思考していけたら幸いです。</p>
      <a class="button _register" href="/a/plan">会員登録はこちら</a>
      </section>
    `).appendTo($m);
  }

  _renderSampleList($m) {
    const $b = $(`
      <section class="list">
      <h3>こんなネタがあるよ</h3>
      <div class="_list"></div>
      <a href="/t?tab=like" class="button">人気のネタをもっとみる</a>
      </section>
    `).appendTo($m);

    const tl = new TopicListWithPagerPartialView({
      c: this.c,
      showPaginate: false,
      params: {limit: 3, type: 'like'},
    });
    tl.render($b.find('._list'));
  }

  _renderNewList($m) {
    // 新着
    const $b = $(`
      <section class="list">
      <h3>新着ネタ</h3>
      <div class="_list"></div>
      <a href="/t" class="button">新着ネタをもっとみる</a>
      </section>
    `).appendTo($m);

    const tl = new TopicListWithPagerPartialView({
      c: this.c,
      showPaginate: false,
      params: {limit: 3},
    });
    // CFunc 負荷軽減
    setTimeout(() => tl.render($b.find('._list')), 4000);
  }

  _renderBookOffer($m) {
    $(`
      <section class="list">
      <h3>書籍 ハック思考 発売中</h3>
        <div style="width:200px; margin:auto;">
          <a href="https://amzn.to/38uMhss" target="_blank">
          <img src="${amznBookImg}" width="200" style="border:1px solid #DDD">
          </a>
        </div>
      <a href="https://amzn.to/38uMhss" class="button" target="_blank">Amazon でチェック</a>
      </section>
    `).appendTo($m);
  }
}
