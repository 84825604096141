import BasePartialView from 'js/view-partial/base';
import ActionMenuTopicPartialView from 'js/view-partial/action-menu/topic';
import EditMenuTopicPartialView from 'js/view-partial/edit-menu/topic';
import PlanUpgradeButtonPartialView from 'js/view-partial/plan-upgrade-button';
import StatusPartialView from 'js/view-partial/status';
import UserPartialView from 'js/view-partial/user';
import TextViewHelper from 'js/view-helper/text';

export default class TopicInfoPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.md = h.md || {}; // model data
    this.$b = undefined;
  }

  render($tgt) {
    const $b = $(`
      <section class="detail">
      <h3>
        <span><img class="_user" src="" alt=""></span>
        <a class="_user"></a><font class="_dt">2h</font>
        <i class="fas fa-lock"></i>
      </h3>
      <p class="_text"></p>
    `);
    $tgt.append($b);
    this.$b = $b;

    // 値を挿入
    this._setDataToDom(this.md);

    // Action UI 追加
    this._addActionMenu();

    // topic の持ち主には Edit UI 追加
    this._addEditMenu();
    return $b;
  }

  _setDataToDom(md) {
    const {$b} = this;
    const text = TextViewHelper.filterTextForDisp(md.data.text);
    const ud = md.user || {};
    //const date = moment(md.createdAt).format('YYYY/MM/DD');
    const date = moment(md.createdAt).fromNow();

    if (!md.data.locked) $b.find('i.fa-lock').remove();

    const $txt = $b.find('p._text');
    if (md.state === 'active') $txt.empty().append(text);
    else $txt.addClass('delete').text('このネタは削除されました');

    const ulHtml = UserPartialView.nameLinkHtml(md.owner, ud.screenName);
    $b.find('a._user').replaceWith(ulHtml);

    const uImgHtml = UserPartialView.imgHtml(
      md.owner,
      ud.picture,
      ud.screenName
    );
    $b.find('img._user').replaceWith(uImgHtml);

    $b.find('font._dt')
      .empty()
      .append(date);

    // 登録プランによる差
    const dtp = md.dispType;
    if (dtp.match(/locked-(free|non-login)/)) {
      const type = RegExp.$1;
      const $bt = $txt.addClass('locked');
      if ($b.find('a._register').length === 0) {
        const $up = new PlanUpgradeButtonPartialView({
          c: this.c,
          type: type,
          disp: 'more',
        });
        $up.render().insertAfter($bt);
      }
    }
  }

  _addEditMenu() {
    if (this.md.dispType !== 'owner') return;
    const {$b} = this;

    const opt = {
      c: this.c,
      md: this.md,
      $target: $b,
      addToDom: ($i, $ul) => {
        this.$b.find('h3').append($i, $ul);
      },
    };
    const em = new EditMenuTopicPartialView(opt);
    em.render();

    $('body').on('topic:update:success', (e, md) => {
      if (this.md.id !== md.id) return;
      this.md.data = md.data;
      this._setDataToDom(md); // 画面に更新内容を反映
    });

    $('body').on('topic:delete:success', () => {
      StatusPartialView.setFlash('success', 'ネタを削除しました');
      window.location = '/t';
    });
  }

  _addActionMenu() {
    const opt = {c: this.c, md: this.md};
    const am = new ActionMenuTopicPartialView(opt);
    am.render().appendTo(this.$b);
  }
}
