import BaseView from 'js/view/base';
import LoadingPartialView from 'js/view-partial/loading';

const storageKey = 'planSelect';

export default class AccountPlanView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('プラン選択');
    this._removePreviousPlanSelect();
    this._render($m);
    console.log('#run done');
  }

  // private methods
  _removePreviousPlanSelect() {
    // 前回の選択プランを消去
    localStorage.removeItem(storageKey);
  }

  _render($m) {
    const $s = $(`
      <section class="signup">
      <h1>プランを選択</h1>
      <p>アカウントには有料プラン・無料プランの 2 種類があります。それぞれの内容は以下になります。いずれかを選んでください。</p>
      </section>
    `).appendTo($m);

    if (this.c.current_user) {
      const ld = new LoadingPartialView({c: this.c});
      ld.render().appendTo($s);
      this.c.user.me().then(r => {
        ld.remove();
        const $b = this._renderContent($s);
        this._initBtnForLoginUser($b, r.data);
      });
    } else {
      const $b = this._renderContent($s);
      this._initBtnForNewUser($b);
    }
  }

  _renderContent($s) {
    const $b = $(`
      <section class="select">
      <h3 id="subsc">有料プラン</h3>
      <ul>
      <li class="selected"><span></span>投稿を読む</li>
      <li class="selected"><span></span>投稿を書く</li>
      <li class="selected"><span></span><i class="fas fa-lock"></i>投稿を読む </li>
      <li class="selected"><span></span><i class="fas fa-lock"></i>投稿を書く</li>
      <li class="selected"><span></span><i class="fas fa-heart"></i>いいねする</li>
      </ul>
      <dl>
      <dd>月額<b>1,100</b>円<small>（税込）</small></dd>
      <dt>の定期支払い</dt>
      </dl>
      <button type="button" class="plan-select paid">有料プランで登録する</button>
      <h3 id="free">無料プラン</h3>
      <ul>
      <li class="selected"><span></span>投稿を読む</li>
      <li class="selected"><span></span>投稿を書く</li>
      <li><span></span><i class="fas fa-lock"></i>投稿を読む </li>
      <li><span></span><i class="fas fa-lock"></i>投稿を書く</li>
      <li class="selected"><span></span><i class="fas fa-heart"></i>いいねする</li>
      </ul>
      <button type="button" class="plan-select free">無料プランで登録する</button>
      <h3 id="non-login">アカウント登録なし</h3>
      <ul>
      <li class="selected"><span></span>投稿を読む</li>
      <li><span></span>投稿を書く</li>
      <li><span></span><i class="fas fa-lock"></i>投稿を読む </li>
      <li><span></span><i class="fas fa-lock"></i>投稿を書く</li>
      <li><span></span><i class="fas fa-heart"></i>いいねする</li>
      </ul>
      </section>
    `).insertAfter($s);
    return $b;
  }

  _initBtnForLoginUser($b, user) {
    console.log('user', user);
    const $paidBtn = $b.find('.plan-select.paid');
    const $freeBtn = $b.find('.plan-select.free');
    if (this.c.currentUserHasSubscPlan()) {
      $paidBtn.prop('disabled', true).text('現在のプラン');
      $freeBtn
        .text('無料プランに変更')
        .on('click', () => (window.location = '/subs/cancel'));
    } else {
      $freeBtn.prop('disabled', true).text('現在のプラン');
      $paidBtn
        .text('有料プランに変更')
        .on('click', () => (window.location = '/subs/create?up=1'));
    }
  }

  _initBtnForNewUser($b) {
    $b.find('.plan-select').on('click', e => {
      // アカ未登録 user のみ ボタン tap 時に選択プランを覚えておく
      if (!this.c.current_user) {
        const $btn = $(e.target);
        const plan = $btn.hasClass('paid') ? 'paid' : 'free';
        localStorage.setItem(storageKey, plan);
      }
      window.location = '/a/create';
    });
  }
}
