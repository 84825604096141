import BaseView from 'js/view/base';
import SlideFormTopicPartialView from 'js/view-partial/slide-form/topic';
import TopicListWithPagerPartialView from 'js/view-partial/list-with-pager/topic';
import StatusPartialView from 'js/view-partial/status';
import SwitchTabPartialView from 'js/view-partial/switch-tab';
import UrlViewHelper from 'js/view-helper/url';

export default class TopicListView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('ネタ一覧');
    this._showAccountCreateStatus();
    this._renderNewTopicBtn($m);
    this._renderSwitchTab($m);
    console.log('#run done');
  }

  _showAccountCreateStatus() {
    // アカ登録から遷移してきた場合
    if (this.c.qprm.get('ac')) {
      const msg = `
        アカウント登録完了がしました。ハック思考へようこそ！
      `;
      StatusPartialView.show('success', msg);
    }
  }

  _renderSwitchTab($m) {
    const $b = $(`
      <section class="list">
      <h3>みんなのネタ</h3>
      </section>
    `).appendTo($m);
    this.$b = $b;

    const tabs = [];
    tabs.push({
      id: 'new',
      dom: '新着順',
      onSelect: () => this._showList($b, 'new'),
      onUnSelect: () => this._hideList($b, 'new'),
    });
    tabs.push({
      id: 'like',
      dom: '人気順',
      onSelect: () => this._showList($b, 'like'),
      onUnSelect: () => this._hideList($b, 'like'),
    });

    const st = new SwitchTabPartialView({
      c: this.c,
      tabs: tabs,
      defaultTabId: 'new',
    });
    st.render().appendTo($b);
  }

  // private methods
  _showList($b, id) {
    const $dom = $b.find(`div._list_${id}`);
    if ($dom.length > 0) {
      // これしとかないと編集した事がある item がタブ切り替えだけで
      // また hilight animation 走る
      $dom.find('.hightlight-fade').removeClass('hightlight-fade');
      return $dom.show();
    }

    if (id === 'new') this._renderNewList($b);
    else if (id === 'like') this._renderLikeList($b);
  }

  _hideList($b, id) {
    $b.find(`div._list_${id}`).hide();
  }

  _renderNewList($b) {
    const html = '<div class="_list_new"></div>';
    const $d = $(html).appendTo($b);

    const tl = new TopicListWithPagerPartialView({
      c: this.c,
      zeroLabel: 'ネタがありません',
      params: {limit: 20},
    });
    tl.render($d);
  }

  _renderLikeList($b) {
    const html = '<div class="_list_like"></div>';
    const $d = $(html).appendTo($b);

    const tl = new TopicListWithPagerPartialView({
      c: this.c,
      zeroLabel: 'ネタがありません',
      params: {limit: 20, type: 'like'},
    });
    tl.render($d);
  }

  _renderNewTopicBtn($m) {
    // ネタ作成ボタン
    const $b = $(`
      <section class="create">
      <button type="button" class="_new"><i class="fas fa-pen"></i>新たなネタを作成</button>
      <p style="text-align:center; margin:20px;font-size:0.7em">
      <a href="https://hack-thinking.zendesk.com/hc/ja/articles/900000320983" target="_blank">ハック思考の楽しみ方</a>
      </p>
      </section>
    `);
    $b.appendTo($m);
    this._setupNewTopicBtn($b, $m);
  }

  _setupNewTopicBtn($b, $m) {
    const $newTopicBtn = $b.find('button._new');
    if (this.c.current_user) {
      // ネタ作成フォーム表示の予約
      $newTopicBtn.on('click', () => {
        const v = new SlideFormTopicPartialView({
          c: this.c,
          closeOnSubmitSuccess: false,
        });
        v.render().appendTo($m);
        v.show();
        return false;
      });

      $('body').on('topic:create:success', (e, md) => {
        const turl = UrlViewHelper.urlFor('topic', 'detail', {id: md.id});
        const twUrl = UrlViewHelper.tweetUrlFor(turl);
        StatusPartialView.setFlash(
          'success',
          `新たなネタを公開しました<br><a href="${twUrl}" target="_blank">twitterでシェアしましょう`
        );
        window.location = turl;
      });
    } else {
      // 未ログインユーザーはアカ登録を促す
      $newTopicBtn.on('click', () => {
        const ok = confirm(
          'ネタの作成には無料アカウントの登録が必要です。アカウント登録をしますか？'
        );
        if (!ok) return false;
        window.location = '/a/plan';
        return false;
      });
    }
  }

  _getListMode() {
    let m = this.c.qprm.get('o') || '';
    if (!m.match(/^(new|hot)$/)) m = 'new';
    return m;
  }
}
