import BaseView from 'js/view/base';
import LoadingPartialView from 'js/view-partial/loading';
import StatusPartialView from 'js/view-partial/status';
import UserPartialView from 'js/view-partial/user';
import UserSegment from 'js/common/user-segment';

export default class AccountSettingsView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('設定');
    this._render($m);
  }

  // private methods
  _render($m) {
    const $s = $('<section class="settings"></section>').appendTo($m);

    const ld = new LoadingPartialView({c: this.c});
    ld.render().appendTo($s);

    this._removePlanSelectStorage();

    return this.c.user.sync().then(r => {
      console.log('user#sync', r);
      ld.remove();
      this._showSubscSuccess();
      this._renderBasicInfo($s);
      this._renderDescInfo($s);
      console.log('#run done');
    });
  }

  _removePlanSelectStorage() {
    // 新規アカ登録時の選択プラン storage 情報を消去しておく
    window.localStorage.removeItem('planSelect');
  }

  _showSubscSuccess() {
    if (!this.c.qprm.get('success')) return;
    if (!this.c.currentUserHasSubscPlan()) return;

    let msg = '有料プランへのアップグレードが完了しました';
    const url = window.localStorage.getItem('planUpgradeClickPage');
    if (url) {
      msg = `
        ${msg}
        <br><br>
        <a href="${url}">先ほどの投稿も読めるようになりました</a>
      `;
    }
    StatusPartialView.show('success', msg);

    // update user segment
    new UserSegment().updateForEvent('upgrade-to-subsc-plan');
  }

  _renderBasicInfo($s) {
    const u = this.c.current_user;
    const ct = moment(u.metadata.creationTime).format('YYYY/MM/DD');
    const uLinkHtml = UserPartialView.nameLinkHtml(u.uid, u.screenName);
    const uImgHtml = UserPartialView.imgHtml(u.uid, u.picture, u.screenName);
    $(`
      <h1>
      <span>${uImgHtml}</span>
      ${uLinkHtml}<small>${ct} 参加</small>
      </h1>
    `).appendTo($s);
  }

  _renderDescInfo($s) {
    const $b = $(`
      <div>
        <h3>契約情報</h3>
        <table id="plan">
        <tbody>
        <tr><th>プラン</th><td id="plan-label"></td></tr>
        </tbody>
        </table>
        <!--//
        <h3>オプション設定</h3>
        <label class="checkbox"><input type="checkbox" name="" value=""><span></span>メール通知を受け取る</label>
        <p>あなたの投稿に対するみんなの反応や、ハック思考からのお知らせなどを、1日1通お送りします。</p>
        //-->
        <h3>オプション設定</h3>
        <button type="button" class="delete _delete">アカウントを削除する</button>
      </div>
    `).appendTo($s);

    let label;
    const $btn = $('<button type="button" class="changes"></button>');
    if (this.c.currentUserHasSubscPlan()) {
      // 有料プランの場合
      label = '有料プラン';
      $btn
        .text('契約状況を確認')
        .on('click', () => (window.location = '/subs'));
    } else {
      // 無料プランの場合
      label = '無料プラン';
      $btn
        .text('有料プランに変更')
        .on('click', () => (window.location = '/subs/create?up=1'));

      // 過去に契約していた場合は履歴ページリンクを表示
      this._showSubscLinkIfChargedBefore($btn);
    }
    $b.find('#plan-label').text(label);
    $btn.insertAfter($s.find('#plan'));

    // 削除ボタン
    $b.find('button._delete').on('click', () => {
      window.location = '/a/resign';
    });
  }

  _showSubscLinkIfChargedBefore($btn) {
    this.c.subsc.getUser().then(r => {
      if (!r.data.customerId) return;
      $(`
        <button type="button" class="delete _subscLink"
          style="margin-top:20px; margin-bottom:60px">
        過去の支払い履歴
        </button>
      `)
        .on('click', () => (window.location = '/subs'))
        .insertAfter($btn);

      // イケてない: 並ぶボタンの位置調整
      $btn.attr('style', 'margin-bottom:0');
    });
  }
}
