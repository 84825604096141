import BaseItem from 'js/model/item/base';

export default class Topic extends BaseItem {
  list(d = {limit: 20}) {
    const p = this._buildListParams(d);
    return this._runCallable(`/topic`, p, 'topics').then(r => {
      const d = r.data;
      this._addDispTypeForCurrentUser(d.topics, d.currentUser);
      return r;
    });
  }

  getWithComments(tid) {
    const p = this._runCallable(`/topic/${tid}/comments`, undefined, 'topic');
    return p.then(r => {
      if (!r.success) return r;
      const d = r.data;
      const {topic} = d;
      this._addDispTypeForCurrentUser(topic);

      this._resolveUsers(topic.comments, d.users);
      this._addDispTypeForCurrentUser(topic.comments);
      this._resolveParentPath(topic.comments);

      (topic.comments || []).forEach(c => {
        this._resolveUsers(c.comments, d.users);
        this._addDispTypeForCurrentUser(c.comments);
        this._resolveParentPath(c.comments);
      });
      return r;
    });
  }

  create(d = {}) {
    return this._runCallable('/topic/create', d);
  }

  update(tid, d = {}) {
    return this._runCallable(`/topic/${tid}/update`, d);
  }

  delete(tid) {
    return this._runCallable(`/topic/${tid}/delete`);
  }

  like(tid) {
    return this._runCallable(`/topic/${tid}/like`);
  }

  unlike(tid) {
    return this._runCallable(`/topic/${tid}/unlike`);
  }

  errorLabelForMsg(msg) {
    const h = super.errorLabelForMsg(msg);
    if (h.label) return h.label;

    const def = {text: 'テキスト'};
    if (h.msg.match(/"([^"]+)" is not allowed to be empty/)) {
      const name = RegExp.$1;
      const lbl = def[name] || '';
      return `${lbl}を入力してください`;
    }
  }
}
