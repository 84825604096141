import AccountController from 'js/controller/account';
import SubscriptionController from 'js/controller/subscription';
import TopController from 'js/controller/top';
import TopicController from 'js/controller/topic';
import UserController from 'js/controller/user';

export default class Router {
  getControllerActionForPath(path) {
    const s = {
      c: undefined, //= controller instance
      a: undefined, //= action method name
      requireLogin: false,
    };

    if (path.match('^/(tos|privacy|law)?$')) {
      s.c = new TopController();
      s.cname = 'TopController';

      if (RegExp.$1) s.a = RegExp.$1;
      else s.a = 'index';
    } else if (path.match('^/t(?:/([^/]+)(?:/(?:c|cc)/[^/]+)?)?$')) {
      s.c = new TopicController();
      s.cname = 'TopicController';

      const m = RegExp.$1;
      if (m !== '') {
        s.c.prm = {id: m};
        s.a = 'detail';
      } else if (path === '/t') {
        s.a = 'list';
      }
    } else if (path.match('^/subs(?:/(create|cancel))?$')) {
      s.c = new SubscriptionController();
      s.cname = 'SubscriptionController';
      s.requireLogin = true;

      if (RegExp.$1) s.a = RegExp.$1;
      else s.a = 'index';
    } else if (path === '/login') {
      s.c = new AccountController();
      s.cname = 'AccountController';
      s.a = 'login';
    } else if (path.match('^/u/([^/]+)$')) {
      s.c = new UserController();
      s.cname = 'UserController';
      s.c.prm = {id: RegExp.$1};
      s.a = 'profile';
    } else if (path.match('^/a/(settings|activities|resign|plan|create)$')) {
      s.c = new AccountController();
      s.cname = 'AccountController';

      s.a = RegExp.$1;
      if (s.a.match('^(settings|activities|resign)$')) s.requireLogin = true;
    }

    return s;
  }
}
