import Base from 'js/common/script-loader/base';

export default class ScriptLoaderFirebaseUi extends Base {
  constructor() {
    super();
    this.src =
      'https://www.gstatic.com/firebasejs/ui/4.3.0/firebase-ui-auth__ja.js';
  }

  _validate() {
    if (!firebaseui.auth.AuthUI)
      throw new Error('Firebase UI class not defined');
    console.log('#ScriptLoaderFirebaseUi loaded');
  }
}
