import Base from 'js/common/script-loader/base';

export default class ScriptLoaderStripe extends Base {
  constructor() {
    super();
    this.src = 'https://js.stripe.com/v3/';
  }

  _validate() {
    if (!window.Stripe) throw new Error('Stripe class not defined');
    console.log('#ScriptLoaderStripe loaded');
  }
}
