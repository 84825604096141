import SlideFormBasePartialView from 'js/view-partial/slide-form/base';

export default class SlideFormCommentPartialView extends SlideFormBasePartialView {
  constructor(h = {}) {
    super(h);
    this.name = 'comment';
    this.topicId = h.topicId;
  }

  _getLabels(type) {
    const lblDef = {
      create: {
        title: 'コメントする',
        btn: 'コメントする',
      },
      update: {
        title: 'コメントを編集',
        btn: '編集完了',
      },
    };
    return lblDef[type] || {};
  }

  _runModelMethod(type, d) {
    if (type === 'create') return this.c.comment.create(this.topicId, d);
    else return this.c.comment.update(this.md.id, d);
  }
}
