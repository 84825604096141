import SlideFormBasePartialView from 'js/view-partial/slide-form/base';

export default class SlideFormTopicPartialView extends SlideFormBasePartialView {
  constructor(h = {}) {
    super(h);
    this.name = 'topic';
  }

  _getLabels(type) {
    const lblDef = {
      create: {
        title: '新たなネタを作成する',
        btn: 'ネタを作成',
      },
      update: {
        title: 'ネタを編集する',
        btn: '編集完了',
      },
    };
    return lblDef[type] || {};
  }

  _runModelMethod(type, d) {
    if (type === 'create') return this.c.topic.create(d);
    else return this.c.topic.update(this.md.id, d);
  }
}
