import BaseView from 'js/view/base';
import LoadingPartialView from 'js/view-partial/loading';
import StatusPartialView from 'js/view-partial/status';
import ButtonViewHelper from 'js/view-helper/button';
import UserSegment from 'js/common/user-segment';

export default class SubscriptionCancelView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('有料プランの解約');
    this._render($m);
    console.log('#run done');
  }

  // private methods
  _render($m) {
    const $s = $(`
      <section class="signup">
      <h1>有料プランの解約</h1>
      </section>
    `).appendTo($m);

    const ld = new LoadingPartialView({c: this.c});
    ld.render().appendTo($s);

    return this.c.subsc.getActive().then(r => {
      console.log('subsc#getActive', r);
      ld.remove();

      // validation: 有効なサブスクがない場合は警告
      if (!r.data.id) {
        const msg = `契約中の有料プランはありません`;
        StatusPartialView.show('error', msg);
        return;
      }

      this._renderContent($s);
      console.log('#run done');
    });
  }

  _renderContent($s) {
    $(`
      <p>月額 1,100 円定期支払いの有料プランを解約します。</p>
      <ul>
      <li>解約手続きが完了した段階で無料プランに戻ります。現在契約中 有料プランの残り提供期間は無効になります。</li>
      <li>解約後も、引き続き無料プラン相当のサービスをご利用になれます。ユーザーアカウント自体は削除されません。</li>
      </ul>
      <small>上記内容に同意の上、</small>
      <button type="button" class="_cancel">有料プランを解約する</button>
    `).appendTo($s);

    const $btn = $s.find('button._cancel');
    const bh = new ButtonViewHelper($btn);
    $btn.on('click', () => {
      bh.startLoading('解約中');
      this._execCancel();
    });
  }

  _execCancel() {
    this.c.subsc.cancel().then(r => {
      // update user segment
      new UserSegment().updateForEvent('downgrade-to-free-plan');

      const msg = '有料プランを解約しました';
      StatusPartialView.setFlash('success', msg);

      console.log('#_renderContent cancel ok', r.data);
      window.location = '/a/settings?sd=1';
    });
  }
}
