import BaseView from 'js/view/base';

export default class TopNotFoundView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('ページがありません');
    this._render($m);
    console.log('#run done');
  }

  // private methods
  _render($m) {
    $(`
      <section class="notes">
      <h1>ページがありません</h1>
      </section>
    `).appendTo($m);
  }
}
