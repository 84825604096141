import BaseView from 'js/view/base';
import SlideFormTopicPartialView from 'js/view-partial/slide-form/topic';
import StatusPartialView from 'js/view-partial/status';
import SwitchTabPartialView from 'js/view-partial/switch-tab';
import TopicListWithPagerPartialView from 'js/view-partial/list-with-pager/topic';
import CommentListWithPagerPartialView from 'js/view-partial/list-with-pager/comment';
import LikeListWithPagerPartialView from 'js/view-partial/list-with-pager/like';
import UrlViewHelper from 'js/view-helper/url';

export default class AccountActivitiesView extends BaseView {
  run() {
    const $m = super.run();
    this._setTitle('自分の投稿');

    this._renderNewTopicBtn($m);
    this._renderSwitchTab($m);

    console.log('#run done');
  }

  // private methods
  _renderSwitchTab($m) {
    const $b = $(`
      <section class="activities">
      <h1>自分の投稿 & いいね</h1>
      </section>
    `).appendTo($m);

    const tabs = [];
    tabs.push({
      id: 'topic',
      dom: '<i class="fas fa-pen"></i>',
      onSelect: () => this._showList($m, 'topic'),
      onUnSelect: () => this._hideList($m, 'topic'),
    });
    tabs.push({
      id: 'comment',
      dom: '<i class="fas fa-comment-dots"></i>',
      onSelect: () => this._showList($m, 'comment'),
      onUnSelect: () => this._hideList($m, 'comment'),
    });
    tabs.push({
      id: 'like',
      dom: '<i class="fas fa-heart"></i>',
      onSelect: () => this._showList($m, 'like'),
      onUnSelect: () => this._hideList($m, 'like'),
    });

    const st = new SwitchTabPartialView({
      c: this.c,
      tabs: tabs,
      defaultTabId: 'topic',
    });
    st.render().appendTo($b);
  }

  _showList($m, id) {
    const $dom = $m.find(`section.list._${id}`);
    if ($dom.length > 0) {
      // これしとかないと編集した事がある item がタブ切り替えだけで
      // また hilight animation 走る
      $dom.find('.hightlight-fade').removeClass('hightlight-fade');
      return $dom.show();
    }

    if (id === 'topic') this._renderTopicList($m);
    else if (id === 'comment') this._renderCommentList($m);
    else if (id === 'like') this._renderLikeList($m);
  }

  _hideList($m, id) {
    $m.find(`section.list._${id}`).hide();
  }

  _renderTopicList($m) {
    const html = '<section class="list _topic"></section>';
    const $b = $(html).appendTo($m);

    const {uid} = this.c.current_user;
    const tl = new TopicListWithPagerPartialView({
      c: this.c,
      zeroLabel: 'まだネタを作成した事がありません',
      showName: false,
      params: {limit: 20, type: 'owner', owner: uid},
    });
    tl.render($b);
  }

  _renderCommentList($m) {
    const html = '<section class="list _comment"></section>';
    const $b = $(html).appendTo($m);

    const {uid} = this.c.current_user;
    const cl = new CommentListWithPagerPartialView({
      c: this.c,
      showName: false,
      zeroLabel: 'まだコメントをした事がありません',
      params: {limit: 20, type: 'owner', owner: uid},
    });
    cl.render($b);
  }

  _renderLikeList($m) {
    const html = '<section class="list _like"></section>';
    const $b = $(html).appendTo($m);

    const {uid} = this.c.current_user;
    const ll = new LikeListWithPagerPartialView({
      c: this.c,
      showName: true,
      zeroLabel: 'まだいいねをした事がありません',
      showItemTypeMark: true,
      params: {limit: 20, owner: uid},
    });
    ll.render($b);
  }

  _renderNewTopicBtn($m) {
    // ネタ作成ボタン
    const $b = $(`
      <section class="create">
      <button type="button" data-action="new-topic"><i class="fas fa-pen"></i>新たなネタを作成</button>
      </section>
    `).appendTo($m);

    const $btn = $b.find('button[data-action="new-topic"]');
    this._setupNewTopicBtn($btn, $m);
  }

  _setupNewTopicBtn($btn, $m) {
    // ネタ作成フォーム表示の予約
    $btn.on('click', () => {
      const v = new SlideFormTopicPartialView({
        c: this.c,
        closeOnSubmitSuccess: false,
      });
      v.render().appendTo($m);
      v.show();
      return false;
    });

    $('body').on('topic:create:success', (e, md) => {
      const turl = UrlViewHelper.urlFor('topic', 'detail', {id: md.id});
      const twUrl = UrlViewHelper.tweetUrlFor(turl);
      StatusPartialView.setFlash(
        'success',
        `新たなネタを公開しました<br><a href="${twUrl}" target="_blank">twitterでシェアしましょう`
      );
      window.location = turl;
    });
  }
}
