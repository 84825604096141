import SlideFormBasePartialView from 'js/view-partial/slide-form/base';

export default class SlideFormChildCommentPartialView extends SlideFormBasePartialView {
  constructor(h = {}) {
    super(h);
    this.name = 'child-comment';
    this.parentCommentId = h.parentCommentId;
  }

  _getLabels(type) {
    const lblDef = {
      create: {
        title: '返信する',
        btn: '返信する',
      },
      update: {
        title: '返信を編集',
        btn: '編集完了',
      },
    };
    return lblDef[type] || {};
  }

  _runModelMethod(type, d) {
    if (type === 'create')
      return this.c.childComment.create(this.parentCommentId, d);
    else return this.c.childComment.update(this.md.id, d);
  }
}
