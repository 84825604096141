import BasePartialView from 'js/view-partial/base';
import CommentListItemPartialView from 'js/view-partial/comment-list-item';
import WindowViewHelper from 'js/view-helper/window';

export default class CommentListPartialView extends BasePartialView {
  constructor(h = {}) {
    super(h);
    this.hasCurrentUsersComment = h.hasCurrentUsersComment || false;
    this.mds = h.mds || [];
  }

  render($m) {
    // ゼロ件の場合は何もしない
    const cmts = this.mds;
    if (cmts.length === 0) return;

    cmts.forEach(c => {
      const opt = {c: this.c, md: c};
      const v = new CommentListItemPartialView(opt);
      v.render().appendTo($m);
    });

    // comment permalink なら該当箇所に scroll
    const pt = this.c.permalinkType();
    if (pt.type === 'comment') {
      const $itm = $m.find(`#c-${pt.id} dt._comment:first`);
      console.log('comment permalink', $itm);
      setTimeout(() => {
        WindowViewHelper.scrollTo($itm, {block: 'start'});
      }, 300);
    }
  }
}
